import { Box, Card, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

function ReviewQuestion(props) {
    let questionTxt;
    let options = [];

    function addInput(str, answer) {
        let filterStr = str;

        if (filterStr.includes("{#1}")) {
            filterStr = filterStr.replace("{#1}", `<br><input class="quizQuestInput" type="text"  id="in1" disabled value="${answer[0] ? answer[0] : ""}"> `);
        }
        if (filterStr.includes("{#2}")) {
            filterStr = filterStr.replace("{#2}", `<input class="quizQuestInput" type="text"  id="in2" disabled value="${answer[1] ? answer[1] : ""}">`);
        }
        if (filterStr.includes("{#3}")) {
            filterStr = filterStr.replace("{#3}", `<input class="quizQuestInput" type="text"  id="in3" disabled value="${answer[2] ? answer[2] : ""}">`);
        }
        if (filterStr.includes("{#4}")) {
            filterStr = filterStr.replace("{#4}", `<input class="quizQuestInput" type="text"  id="in4" disabled value="${answer[3] ? answer[3] : ""}">`);
        }
        if (filterStr.includes("{#5}")) {
            filterStr = filterStr.replace("{#5}", `<input class="quizQuestInput" type="text"  id="in5" disabled value="${answer[4] ? answer[4] : ""}">`);
        }
        return <div className="reviewQuestionTxt" dangerouslySetInnerHTML={{ __html: filterStr }} />
    }

    console.log("propsA", props)

    function createHTML(str) {
        return <div className="reviewQuestionTxt" dangerouslySetInnerHTML={{ __html: str }} />
    }
    if (props.type === "multianswer") {
        questionTxt = addInput(props.questionText, props.answer);

    } else if (props.type === 'multichoice') {
        questionTxt = props.questionText;
        options = props.options.map(val => {
            return <FormControlLabel value={val.answerText} disabled control={<Radio />} label={createHTML(val.answerText)} checked={val.isChecked} />
        });
    } else if (props.type === "truefalse") {

        questionTxt = props.questionText;
    }


    let bgColor = "#204abd";
    let border = "1px solid #b9b9b9";
    if(props.status === "Correct"){
        bgColor = "#aeff92";
        border = "1px solid rgb(87 191 51)";
    }
    if(props.status === "Incorrect"){
        bgColor = "#ffc6c6";
        border = "1px solid rgb(247 144 144)";
    }
    

    return (
        <Card
            maxWidth="xl"
            id={`Qno-${props.qNo}`}
            sx={{
                p: 1,
                mb: 4,
                backgroundColor: "cardColor.main",
                color: "cardColor.contrast",
                textAlign: "center",
                position: "relative"
            }}
            className="customCard questforReview"
            style={{border: border, boxShadow: "none" }}
        >

            <Typography variant='h4' sx={{ bgcolor: bgColor, color: '#000'}} >
                <Box>Question: {props.qNo}</Box><Box pr={2}>{props.status}</Box>
            </Typography>
            <Box className="reviewQuestionTxt">{questionTxt}</Box>
            {
                props.type === `multichoice` &&
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                >
                    {options}
                </RadioGroup>
            }
            {
                props.type === 'truefalse' &&
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="True" disabled control={<Radio />} label="True" checked={props.answerAttempt && true} />
                    <FormControlLabel value="False" disabled control={<Radio />} label="False" checked={!props.answerAttempt && true} />
                </RadioGroup>
            }
            <Box className="infoContainer" sx={{border: border, borderBottom: 0, borderLeft: 0, borderRight: 0,}}>
                <Box>
                    {props.status === "Incorrect" && <Box>Correct Answer is: {props.correctAnswer}</Box>}
                    {props.status === "Not answered" && <Box>Correct Answer is: {props.correctAnswer}</Box>}
                </Box>
                {props.status !== "Not answered" && <Box>Marks: {props.mark}</Box>}
            </Box>
        </Card>
    )
}

export default ReviewQuestion
