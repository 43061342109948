import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { languages } from "../../App";
import "./QuizAttemptSummary.scss";
import {
  Box,
  Button,
  Card,
  Container,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import QuizSummaryList from "./QuizSummaryList";
import Loader from "../../_Components/Utils/loader/Loader";
const QuizAttemptSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizSubmited, setQuizSubmited] = useState(false);
  const navigation = useNavigate();
  let curLanguage = useContext(languages);
  const stateData = useLocation();
  const token = stateData.state.token;
  const attId = stateData.state.assessmentId;
  const quizID = stateData.state.quizID;

  console.log(quizID);

  const [attemptSummary, SetattemptSummary] = useState();

  const attemptSummaryURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${attId}`;

  const submitAttemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_process_attempt&moodlewsrestformat=json&attemptid=${attId}&finishattempt=1`;

  useEffect(() => {
    axios.get(attemptSummaryURL).then((response) => {
      console.log(response.data, "attemptSummary");
      SetattemptSummary(response.data);
    });
  }, []);

  const values =
    attemptSummary &&
    attemptSummary.questions.map((values, index) => {
      return index, values.status;
    });

  const sumbitHandler = () => {
    setIsLoading(true);
    setQuizSubmited(true);
    console.log(submitAttemptURL);
    axios.get(submitAttemptURL).then((response) => {
      //navigation("/GetCourseContent/modAccessInformation",{state:{id: quizID}})
      setIsLoading(false);
    });
  };

  let quizSubmitContent = ``;

  if (quizSubmited) {
    if (isLoading) {
      quizSubmitContent = <Loader />;
    } else {
      quizSubmitContent = "Quiz Submitted Successfully";
    }
  }
  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard attemptCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>Assessment</b>
          </Box>
        </Typography>
        <Box className="customCardContent" p={2}>
          <Typography variant="h6" py={1}>
            Attempts Summary
          </Typography>

          {console.log(quizSubmited, isLoading)}

          {quizSubmitContent}

          {!quizSubmited && (
            <QuizSummaryList
              attemptSummary={attemptSummary}
              attId={attId}
              sumbitHandler={sumbitHandler}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
};
export default QuizAttemptSummary;
