import "./UserProfileDetails.scss";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { languages } from "../../App";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FiEdit } from "react-icons/fi";
import ProfileBox from "./ProfileBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProfileImg from "./ProfileImg";
import ChangePassword from "../ChangePassword/ChangePassword";
import CustomSnackBar from "../../_Components/Common/SnackBar/CustomSnackBar";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";

function UserProfileDetails() {
  let curLanguage = useContext(languages);
  const [profileInfo, setProfileInfo] = useState("");
  const [ContactFirst, setContactFirst] = useState("");
  const [ContactSecond, setContactSecond] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [error, setError] = useState("");
  const [saveImg, setSaveImg] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userinfoObject[0].id;
  const username = userinfoObject[0].id;
  let fullDate = "";
  const teacher = userinfoObject[0].department.toLowerCase() === "pedagogy";
  const student = userinfoObject[0].department.toLowerCase() === "student";
  const [updatedProfile, setUpdatedProfile] = useState();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getUserProfile?userid=${userId}`
      )
      .then((response) => {
        let dob = response?.data?.info?.[0]?.dob1;
        let cc = dob ? dob.split("-") : ["-", "-", "-"];
        fullDate = cc.length === 3 ? `${cc[2]}-${cc[1]}-${cc[0]}` : "- - -";

        console.log(fullDate, " nno");
        setProfileInfo(response.data.info);
        setFormData({
          ...formData,
          gender: profileData.gender,
          dob: fullDate,
        });
      });
  }, []);
  const profileData = profileInfo && profileInfo[0];
  const dobDate = profileData.dob1;
  //Edit Form Information Area

  const handleEditing = () => {
    setIsEditing(false);
  };

  const [formData, setFormData] = useState({
    firstname: profileData.firstname,
    lastname: profileData.lastname,
    phone1: profileData.phone1,
    phone2: profileData.phone2,
    gender: profileData.gender,
    dob: fullDate,
    city: profileData.city,
    state: profileData.state,
    country: profileData.country,
    address: profileData.address,
  });
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };
  const handleDateChange = (date) => {
    // Format the date before updating the state
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";

    setFormData((prevData) => ({
      ...prevData,
      dob: formattedDate,
    }));
  };

  const saveEditing = async (event) => {
    event.preventDefault();
    const staticDateInfo = formData.dob.split("-");
    const staticDate = `${staticDateInfo[0]}-${staticDateInfo[1]}-${staticDateInfo[2]}`;
    console.log(ContactFirst, ContactSecond, "ContactSecondContactSecond");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_nodewsPrefix}/updateUserProfile`,
        {
          userid: userId,
          firstname: formData.firstname || profileData.firstname,
          lastname: formData.lastname || profileData.lastname,
          phone1: ContactFirst || profileData.phone1,
          phone2: ContactSecond || profileData.phone2,
          address: formData.address || profileData.address,
          city: city || profileData.city,
          country: formData.country || profileData.country,
          dob: staticDate || fullDate,
          gender: formData.gender || profileData.gender,
          state: states || profileData.state,
        }
      );
      console.log(response.data, "rajji");
      setUpdatedProfile(response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }

    console.log(staticDate, formData.dob, "dob");
    setIsEditing(true);
    setOpenSnackbar(true);
  };

  const backHandling = () => {
    setIsEditing(true);
  };

  const profileImgInfo = userinfoObject[0]?.profileimageurl;

  const handleImgUpload = () => {
    setSaveImg(true);
  };
  const handleContactChangeFirst = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setContactFirst(limitedValue);
  };
  const handleContactChangeSecond = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setContactSecond(limitedValue);
  };
  const handleCityNameChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setCity(filteredValue);
  };
  const handleStateNameChange = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setStates(filteredValue);
  };
  return (
    <Box className="profileWrapper">
      <MainCard
        className="profilecontent"
        mainPage={true}
        cardTitle="Manage Profile"
      >
        {profileInfo ? (
          <form onSubmit={saveEditing}>
            <CustomSnackBar
              message={"Profile updated successfully!"}
              open={openSnackbar}
              setOpen={setOpenSnackbar}
            />

            <Grid container className="profiler" spacing={2}>
              <Grid item lg={2} md={3} sm={12} xs={12} className="imgBox">
                <ProfileImg
                  isEditing={isEditing}
                  profileImgInfo={profileImgInfo}
                  userId={userId}
                  onImgUpload={handleImgUpload}
                  username={username}
                />
                <Box className="profileInformation">
                  <Typography variant="h6">
                    {profileInfo[0].firstname} {profileInfo[0].lastname}
                  </Typography>
                  <Typography>{teacher ? "Teacher" : ""}</Typography>
                  <Typography>
                    <small>
                      Last Access:
                      <br /> {profileInfo[0].lastaccess}
                    </small>
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={10} md={9} sm={12} xs={12} className="imgBox">
                <Grid container className="profileInfo" spacing={2}>
                  {student && (
                    <ProfileBox>
                      <FormLabel>Roll number</FormLabel>
                      <Typography>{profileInfo[0].idnumber}</Typography>
                    </ProfileBox>
                  )}

                  <ProfileBox>
                    <FormLabel>{curLanguage.username}</FormLabel>
                    <Typography>{userinfoObject[0].username}</Typography>
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.Emailaddress}</FormLabel>
                    <Typography>{userinfoObject[0].email}</Typography>
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Joined on</FormLabel>
                    <Typography>{profileInfo[0].timecreated}</Typography>
                  </ProfileBox>

                  <ProfileBox>
                    <FormLabel>Gender</FormLabel>
                    <Typography>
                      {profileInfo[0].gender === "F" ? "Female" : "Male"}
                    </Typography>
                  </ProfileBox>

                  <ProfileBox>
                    <FormLabel>Contact 1</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {ContactFirst ? ContactFirst : profileInfo[0].phone1}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].phone1}
                        name="phone1"
                        value={ContactFirst}
                        onChange={handleContactChangeFirst}
                        type="tel"
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Contact 2</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {ContactSecond ? ContactSecond : profileInfo[0].phone2}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].phone2}
                        name="phone2"
                        value={ContactSecond}
                        onChange={handleContactChangeSecond}
                        type="tel"
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>Address</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {formData.address
                          ? formData.address
                          : profileInfo[0].address}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].address}
                        name="address"
                        value={formData.address}
                        onChange={onHandleChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.Citytown}</FormLabel>
                    {isEditing ? (
                      // <Typography>{profileInfo[0].city}</Typography>
                      <Typography>
                        {city ? city : profileInfo[0].city}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].city}
                        name="city"
                        value={city}
                        onChange={handleCityNameChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.state}</FormLabel>
                    {isEditing ? (
                      <Typography>
                        {states ? states : profileInfo[0].state}
                      </Typography>
                    ) : (
                      <TextField
                        id="outlined"
                        placeholder={profileInfo[0].state}
                        name="state"
                        value={states}
                        onChange={handleStateNameChange}
                      />
                    )}
                  </ProfileBox>
                  <ProfileBox>
                    <FormLabel>{curLanguage.country}</FormLabel>
                    <Typography>
                      {profileInfo[0].country === "IN" && "India"}
                    </Typography>
                  </ProfileBox>
                </Grid>
              </Grid>
            </Grid>
            <Box className="subTexts">
              {isEditing ? (
                <>
                  <PrimaryBgBtn
                    onClick={handleEditing}
                    startIcon={<FiEdit size={20} />}
                  >
                    {curLanguage.editprofile}
                  </PrimaryBgBtn>
                  <ChangePassword />
                </>
              ) : (
                <>
                  <Button
                    onClick={backHandling}
                    className="btn"
                    variant="outlined"
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="btn"
                  >
                    {curLanguage.save}
                  </Button>
                </>
              )}
            </Box>
          </form>
        ) : (
          ""
        )}
      </MainCard>
    </Box>
  );
}
export default UserProfileDetails;
