import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./dashboard.module.scss";
import RecentItems from "../recentItems/RecentItems";
import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AdminDashboard from "./AdminUser/AdminDashboard";
import MyClasses from "../myCourses/MyClasses";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";
import PrimaryCard from "../../_Components/Common/Cards/PrimaryCard";
import ChapterProgress from "../../_Components/Common/Charts/ChapterProgress";
import TeacherSyllabus from "../../_Components/Common/Charts/TeacherSyllabus";
import MyCourse from "../recentCourse/MyCourse";
import PrincipalDashboard from "../PrincipalUser/Dashboard/PrincipalDashboard";

function Dashboard(props) {
  const { permissions } = usePermissions();
  const token = localStorage.getItem("token");
  const [teacherSubjects, setTeacherSubjects] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <Box className={styles.dashboardContainer}>
      <Container maxWidth="xl">
        {permissions.admindashboard?.write &&
          permissions.admindashboard?.read && (
            <DndProvider backend={HTML5Backend}>
              <AdminDashboard />
            </DndProvider>
          )}
        {permissions.principalDashboard?.write &&
          permissions.principalDashboard?.read && <PrincipalDashboard />}
        {permissions.dashboard?.write && permissions.dashboard?.read && (
          <>
            <Box className={styles.dashboardFrame} pb={3}>
              <Box>
                {permissions.classes?.write && (
                  <PrimaryCard
                    curLanguage="Syllabus Completed"
                    titleClass="mainHeading"
                  >
                    <Box width={"100%"} sx={{ minHeight: "300px" }}>
                      <TeacherSyllabus
                        theme="light"
                        userInfo={userInfo}
                        teacherSubjects={teacherSubjects}
                      />
                    </Box>
                  </PrimaryCard>
                )}
              </Box>
              <Box>
                {permissions.classes?.write && (
                  <PrimaryCard
                    curLanguage="Students per Class"
                    titleClass="mainHeading"
                  >
                    <ChapterProgress theme="light" />
                  </PrimaryCard>
                )}
              </Box>
            </Box>
            <Box className={styles.dashboardFrame}>
              {permissions.classes?.write && <MyClasses />}
              {permissions.courses?.write && <MyCourse />}
              {(permissions.classes?.write || permissions.courses?.write) && (
                <RecentItems token={token} />
              )}
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}

export default Dashboard;
