import React from "react";
import ReactEcharts from "echarts-for-react";

const StudentProgressChart = ({ activity }) => {
  const activityNames = Object.keys(activity);
  const seriesData = activityNames.map((name) => {
    const completedCount = activity[name].filter(
      (chapter) => chapter.status?.toLowerCase() === "completed"
    ).length;
    const totalCount = activity[name].length;
    return {
      name: name,
      total: totalCount,
      Obtained: completedCount,
    };
  });
  const totalActivity = seriesData.map((data) => data.total);
  const obtainedActivity = seriesData.map((data) => data.Obtained);
  const processLabel = (label) => {
    const words = label.split(' ');
    if (words.length > 1) {
      return words.join('\n');
    }
    return label;
  };
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: activityNames,
      splitLine: {
        show: false
      },
      axisLabel: {
        interval: 0,
        rotate: 0,
        textStyle: {
          fontSize: 12,
          fontWeight: "bold",
          color: "#000000",
        },
        formatter: processLabel
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    toolbox: {
      show: true,
      orient: "vertical",
      right: 10,
      top: 0,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        magicType: { show: false, type: ["line"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Obtained",
        type: "bar",
        data: obtainedActivity,
        color: ["#5470c6"],
      },
      {
        name: "Total",
        type: "bar",
        data: totalActivity,
        color: ["#91cc75"],
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default StudentProgressChart;
