import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import { HiOutlineDownload } from "react-icons/hi";
import Loader from "../../Utils/loader/Loader";
import { PrimaryBgBtn } from "../Buttons/CustomBtns";


const CreatePDF = ({ id, fileName, orientation, btnTitle }) => {
  const [loading, setLoading] = useState(false);

  const PdfOptions = {
    filename: `${fileName}.pdf`,
    method: "save",
    page: {
      margin: Margin.SMALL,
      format: "A4",
      orientation: orientation,
    },
    canvas: {
      mimeType: "image/jpeg",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  const getTargetElement = () => document.getElementById(id);

  const createPDF = async () => {
    setLoading(true);
    const targetElement = getTargetElement();
    targetElement.style.overflow = 'visible';
    await generatePDF(getTargetElement, PdfOptions);
    targetElement.style.overflow = '';
    setLoading(false);
  };

  const fetchData = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 2000); 
    });
  };

  const handleClick = async () => {
    try {
      setLoading(true);
      await fetchData();
      await createPDF();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PrimaryBgBtn
        className="btn primaryBtn"
        variant="contained"
        onClick={handleClick}
        disabled={loading} 
        startIcon={<HiOutlineDownload />}
      >
        {btnTitle}
      </PrimaryBgBtn>
      {loading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
        </Box>
      )}
    </>
  );
};

export default CreatePDF;

