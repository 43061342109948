import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    themeColor: {
      bgColor: "#1a2b3d",
    },
    primary: {
      main: "#203bb3",
      contrastText: "#fff",
    },
    //color combinations
    redColor: {
      color: "#ee5558",
      background: "#f8dedf",
    },
    blueColor: {
      color: " #3279e5",
      background: "#d5edf9",
    },
    greenColor: {
      color: "#32c076",
      background: "#daeeef",
    },
    yellowColor: {
      color: "#edca4a",
      background: "#f9f2c4",
    },
    purpleColor: {
      color: "#9e4ee1",
      background: "#f6e4fe",
    },
    orangeColor: {
      color: "#f08e37",
      background: "#fce8cd",
    },

    secondaryColor: {
      main: "#2b3b4c",
      contrastText: "#fff",
    },
    insideCard: {
      main: "#28537e",
      borderColor: "#12263a",
    },
    secondaryColorOutline: {
      main: "#fff",
      border: "#204abd",
      contrastText: "#000",
    },
    categoryEventBtn: {
      main: "transparent",
      contrastText: "#00ffff",
    },
    userEventBtn: {
      main: "#00ffff",
      contrastText: "#000000",
    },
    teacherEventBtn: {
      main: "#d6d2ff",
      contrastText: "#000000",
    },
    buttons: {
      main: "#fff",
      contrastText: "#000",
    },
    grayButton: {
      main: "#dedede",
      contrastText: "#373737",
    },
    extraButton: {
      main: "#39811e",
      contrastText: "#fff",
    },
    loginButton: {
      main: "#ff0000",
      contrastText: "#fff",
    },
    userPopup: {
      main: "#152332",
      contrastText: "#fff",
    },
    homeGreen: {
      main: "#39811e",
      contrastText: "#fff",
    },
    whiteBG: {
      main: "#fff",
    },
    closeButton: {
      main: "#000",
      contrastText: "#000",
    },

    profileButton: {
      main: "#47a025",
      contrastText: "#fff",
    },
    whiteButton: {
      main: "#fff",
      contrastText: "#fff",
    },
    FactsButton: {
      main: "#a4243b",
      contrastText: "#fff",
    },
    calendarWhiteButton: {
      main: "#5b656f",
      contrastText: "#fff",
    },
    calendarButton: {
      main: "transparent",
      contrastText: "#000",
    },
    fackbookButton: {
      main: "#3b5998",
      contrastText: "#fff",
    },
    twitterButton: {
      main: "#1da1f2",
      contrastText: "#fff",
    },
    linkedinButton: {
      main: "#0077b5",
      contrastText: "#fff",
    },
    instagramButton: {
      main: "linear-gradient(180deg, #fff, #d1d1d1)", //linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)
      contrastText: "#fff",
    },
    youtubeButton: {
      main: "red",
      contrastText: "#fff",
    },
    resourceIcon: {
      main: "#ff4e00",
      contrastText: "#000",
    },
    switchButton: {
      main: "#000",
      contrastText: "#fff",
    },
    headerBack: {
      main: "#1a2b3d",
    },
    borderLine: {
      main: "#2b3b4c",
    },
    mainNavigator: {
      main: "#1a2b3d",
      mainGradient: "linear-gradient(to bottom, #1a2b3d, #485869)",
    },
    calHeader: {
      mainGradient:
        "linear-gradient(90deg, rgba(0,187,255,1) 0%, rgb(125 43 255) 100%)",
    },
    calDate: {
      mainGradient: "linear-gradient(-14deg, #00d2ff 0%, #7b2eff 147%)",
    },
    adminBtnGradient: {
      mainGradient: "linear-gradient(180deg, #6581cf, #022073)",
    },
    footer: {
      bgColor: "#1a2b3d",
    },
    darkblue: {
      color: "#334559",
    },
    sidebar: {
      color: "#14273c",
    },
    footerBg: {
      main: "#1a2b3d",
      contrastText: "#fff",
      bgColor: "#13202e",
    },
    black: {
      main: "#000",
    },
    popupbutton: {
      main: "#8D72E1",
      contrastText: "#fff",
    },
    dashboard: {
      main: "#1a2b3d",
    },
    recentSub: {
      main: "#ccc",
    },

    darkerBg: {
      bgColor: "#0d1723",
    },

    recent: {
      titleColor: "#fff",
      titlebgColor: "#00000033",
    },

    commonH: {
      titleColor: "#fff",
    },

    subBoxes: {
      bgColor: "#323f4f",
      textColor: "#fff",
    },

    calendar: {
      whiteColor: "#000",
      blackColor: "#fff",
      bgcolor: "#becee3",
      outerbgColor: "#2C74B3",
      containerbgColor: "#152231",
      dayBgColor: "#144272",
      borderColor: "becee3",
    },
    popup: {
      titleHeaderColor: "#2b3b4c",
      popupFooter: "#8D72E1",
      popupheadertextcolor: "#fff",
      popupclose: "#1a2b3d",
    },
    assestBox: {
      bgcolor: "#000",
    },
    resourcesBox: {
      bgcolor: "#eaeaea",
    },
    coursecontent: {
      titlebgColor: "#b90600",
      textColor: "#fff",
    },
    assessmentAttempt: {
      main: "#1a2b3d",
    },

    QuizBox: {
      bgColor: "#00000033",
      textColor: "#fff",
    },
    quizDescription: {
      TitlebgColor: "#47a025",
      titleTextColor: "#b90600",
      CheckColor: "#b90600",
    },
    Quiznavigation: {
      main: "#fff",
      mainGradient: "linear-gradient(to bottom,#f0f0f0 0%,#fdfdfd 100%)",
      textColor: "#333",
      numTopbgColor: "#47a025",
      numBottombgColor: "#47a025",
      quiznumText: "#fff",
    },
    Pagination: {
      main: "#a4243b",
      textColor: "#fff",
    },
    Snackbar: {
      textColors: "#fff",
      main: "#1a2b3d",
      textColor: "#fff",
    },
    tooltip: {
      main: "#fff",
      backgroundColor: "#152231",
    },
    //Colors
    blueShade: {
      bg: "#1a2a3c",
      color: "#d3d3d3",
    },
    yellowShade: {
      bg: "#2b3b4c",
      color: "#c9c9c9",
    },
    orange: {
      bg: "#1a2b3d",
      bgDark: "#1a2b3d",
      contrast: "#485564",
      text: "#ebebeb",
    },
    purple: {
      bg: "#1a2b3d",
      bgDark: "#1a2b3d",
      contrast: "#485564",
      text: "#ebebeb",
    },
    green: {
      bg: "#1a2b3d",
      bgDark: "#1a2b3d",
      contrast: "#485564",
      text: "#ebebeb",
    },
    grey: {
      bg: "#1a2b3d",
      bgDark: "#1a2b3d",
      contrast: "#485564",
      text: "#ebebeb",
    },
    linkTxt: {
      color: "#fff",
    },
    table: {
      headerBg: "#14283c",
      contrast: "#dfdfdf",
    },
    //Admission Form Theme scss
    lightBg: {
      textColor: "#000000",
      bgColor: "f2f4f6",
    },
    icons: {
      textColor: "#c4c4c4",
      bgColor: "eef6fe",
    },
    iconBg: {
      main: "#3f6183",
      contrast: "#ebebeb",
      hover: "#dbebfb",
    },
    iconColor: {
      color: "#0e1c2a",
    },
    textsColor: {
      color: "#ebebeb",
      secColor: "#d0cfcf",
    },
    labelColor: {
      color: "#a19d9d",
    },
    cardColor: {
      main: "#20374e",
      contrast: "#ebebeb",
    },
    tab: {
      bgColor: "#15283c",
    },
    yellow: {
      color: "#ffb703",
    },
    //certificate theme color
    template: {
      bgColor: "#1a2b3d",
      color: "#ebebeb",
      borderColor: "#495d70",
      headColor: "#273c53",
    },
    accordion: {
      bg: "#1f354b",
      color: "#ebebeb",
    },
  },
});
const theme2 = createTheme({
  palette: theme.palette,
  typography: {
    font: {
      fontsize10: "10px",
      fontsize20: "20px",
      fontsize30: "30px",
      fontsize40: "40px",
      fontsize50: "25px",
      fontsize60: "18px",
    },
  },
  components: {
    MuiGrid: {
      variants: [
        {
          props: { variant: "backgroundImage" },
          style: {
            backgroundImage:
              "url(http://moodle.ratnasagar.com/moodle/app-data/resources/images/bg-images/pattern_bg_dark.jpg)",
          },
        },
        {
          props: { variant: "gradient" },
          style: {
            backgroundImage: theme.palette.mainNavigator.mainGradient,
          },
        },
        {
          props: { variant: "gradient2" },
          style: {
            backgroundImage: theme.palette.calHeader.mainGradient,
          },
        },
        {
          props: { variant: "gradient3" },
          style: {
            backgroundImage: theme.palette.calDate.mainGradient,
          },
        },
        {
          props: { variant: "btnGradient" },
          style: {
            backgroundImage: theme.palette.adminBtnGradient.mainGradient,
          },
        },
        {
          props: { variant: "activeCalendar" },
          style: {
            background: "#becee3",
            border: "3px solid #becee3",
            boxShadow: "0 3px 8px 0px rgba(255, 255 ,255 ,0.5) !important",
            color: "#2b3b4c",
          },
        },

        {
          props: { variant: "inactiveCalendar" },
          style: {
            background: "#3b638b",
            borderTop: "3px solid #becee3",
            color: "#fff",
          },
        },
        {
          props: { variant: "gradient4" },
          style: {
            backgroundImage: theme.palette.Quiznavigation.mainGradient,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },

      variants: [
        {
          props: { variant: "btngradient" },
          style: {
            backgroundImage:
              "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
            color: "#ffffff",
          },
        },
      ],
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            color: "#c4c4c4",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c4c4c4",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#a19d9d", // Set your desired border color on hover
          },
          "&::placeholder": {
            color: "#c4c4c4",
          },
          "&:hover ::placeholder": {
            color: "#a19d9d", // Set your desired border color on hover
          },
          "& label": {
            color: "#c4c4c4",
          },
        },
        adornedEnd: {
          "& .MuiSvgIcon-root": {
            fill: "#fff", // Set the desired color for icons like dropdown and calendar
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#c4c4c4", // Set the desired color for the dropdown arrow
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#c4c4c4", // Set your desired label color for the dark theme
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#c4c4c4", // Set the desired color for the radio icon
          checked: {
            color: "#fff", // Set the desired color for the radio button when checked
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#fff", // Set the desired color for the radio button when checked
          },
        },
      },
    },
  },
});

export default theme2;
