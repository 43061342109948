import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router";
import "./getCourseContent.scss";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import AssetsBox from "./AssetsBox";
import "./AssetsBox.scss";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, NavLink } from "react-router-dom";
import { languages } from "../../App";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import CardPrimary from "../../_Components/Common/Cards/CardPrimary";
import { Scrollbar } from "react-scrollbars-custom";
import AccordionChildItem from "./AccordionChildItem";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";

let updatedMainData;

function GetCourseContent() {
  const [open, setOpen] = useState(false);
  const [coursecontent, setCoursecontent] = useState();
  const [loading, setLoading] = useState(true);
  let curLanguage = useContext(languages);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const courseId = searchParams.get("id");
  const fullname = searchParams.get("fullname");
  const stdclass = searchParams.get("stdclass");
  const section = searchParams.get("section");
  const classid = searchParams.get("classid");

  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const courseName = fullname;

  const [isClassTeacher, setIsClassTeacher] = useState(false);
  localStorage.setItem("breadCrumbCourseID", courseId);
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const stdClassInfo = JSON.parse(localStorage.getItem("classInfo"));
  const studentName = userinfoObject[0].fullname;
  const teacherMore =
    userinfoObject && userinfoObject[0].department === "Pedagogy";
  const [activityData, setActivityData] = useState([]);
  const [syllabusCheck, setSyllabusCheck] = useState();
  const [teacherCompletion, setTeacherCompletion] = useState();
  const [syllabusValue, setSyllabusValue] = useState();
  const userID = userinfoObject?.[0].id;
  const financialYear = localStorage.getItem("financialYear");

  useEffect(() => {
    feachClassTeacher();
    const get_Course_Content = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_course_get_contents_info&moodlewsrestformat=json&courseid=${courseId}`;
    axios
      .get(get_Course_Content)
      .then((response) => {
        setCoursecontent(response.data);
        console.log(response.data, "vlssssssssssss");
        console.log("Chapter: vlssssssssssss", response.data[2].child[0].modules[0] );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });

    const fetchActivityData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/viewActivityCompletion`;
        const response = await axios.post(reportDataURL,{
          course_id: courseId,
          userid: userinfoObject[0].id,
        },{
          headers: {
            Authorization: `Bearer ${nToken}`
          }
        });
        setActivityData(response.data.course_data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchActivityData();

    const getData = async () => {
      try {
        let response = await axios.post(`${process.env.REACT_APP_nodewsPrefix}/viewCourseSectionCompletion`,
          {
            courseid: courseId,
            classid: classid,
            teacherid: userID,
            financial_year: financialYear,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`
            }
          }
        );
        setSyllabusCheck(response?.data.data);
        setSyllabusValue(response?.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();

    if (userinfoObject?.[0].department.toLowerCase() === "student") {
      setTeacherCompletion(syllabusValue);
    }
  }, [courseId]);

  const activityDetails = activityData?.map((data) => data.section_name);

  const feachClassTeacher = async () => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfoObject[0]?.id}&schoolcode=${userinfoObject[0]?.institution}`;
    const response = await axios.get(baseURL, {
      headers: {
        Authorization: `Bearer ${nToken}`
      }
    });

    response.data.rows.map((val) => {
      if (courseId == val.courseid && classid == val.id) {
        setIsClassTeacher(Boolean(val.is_classteacher));
      }
    });

    const enroledURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${courseId}`;
    const response1 = await axios.get(enroledURL);
    const studentData = response1.data.filter(
      (val) =>
        val.department?.toLowerCase() === "student" &&
        val.section === section &&
        val.institution === userinfoObject[0].institution
    );
    localStorage.setItem("students", JSON.stringify(studentData));
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const initialFormDFata = {
    name: "",
    type: "",
    file: "",
    url: "",
    chidID: "",
    directChildID: "",
  };

  const [formName, setFormName] = useState(initialFormDFata);
  const [formType, setFormType] = useState();

  const handleChange = (event) => {
    setFormName((prevState) => ({ ...prevState, name: event.target.value }));
  };
  const handleChangeType = (event) => {
    setFormName((prevState) => ({ ...prevState, type: event.target.value }));
  };

  const onURLChange = (event) => {
    setFormName((prevState) => ({ ...prevState, url: event.target.value }));
  };

  const handleFileChange = (event) => {
    setFormName((prevFormData) => ({
      ...prevFormData,
      url: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formType === "main") {
      let titleId = crypto.randomUUID();
      let childId = crypto.randomUUID();
      let moduleID = crypto.randomUUID();

      let alreadyExist = false;

      alreadyExist = coursecontent.some((data) => {
        return data.name === "Additional Resources";
      });

      if (alreadyExist) {
        let updatedChild = {
          id: childId,
          name: formName.type,
          visible: "1",
          summary: "",
          summaryformat: "1",
          section: 7,
          hiddenbynumsections: 0,
          uservisible: true,
          modules: [
            {
              id: moduleID,
              name: formName.name,
              instance: "267",
              contextid: 19786,
              modname: "scorm",
              modplural: "Custom",
              modicon:
                "https://test-lms.ratnasagar.com/theme/image.php/mb2aofk/scorm/1691127108/monologo",
              indent: 0,
              onclick: "",
              afterlink: null,
              customdata: '""',
              completion: 0,
              downloadcontent: "1",
              noviewlink: false,
              dates: [],
              url: formName.url,
              visible: "1",
              visibleoncoursepage: 1,
              uservisible: true,
            },
          ],
        };

        const updateData = coursecontent.map((data) => {
          if (data.id === updatedMainData.id) {
            let added = false;
            data.child.map((item) => {
              if (item.name === formName.type) {
                item.modules.push(formName);
                added = true;
              }
              return 0;
            });
            if (!added) {
              data.child.push(updatedChild);
            }
          }
          return 0;
        });
        setCoursecontent((prevState) => [...prevState, updateData]);
      } else {
        updatedMainData = {
          id: titleId,
          name: "Additional Resources",
          visible: "1",
          summary: "",
          summaryformat: "1",
          section: 6,
          hiddenbynumsections: 0,
          uservisible: true,
          modules: [],
          child: [
            {
              id: childId,
              name: formName.type,
              visible: "1",
              summary: "",
              summaryformat: "1",
              section: 7,
              hiddenbynumsections: 0,
              uservisible: true,
              modules: [
                {
                  id: moduleID,
                  name: formName.name,
                  instance: "267",
                  contextid: 19786,
                  modname: "scorm",
                  modplural: "Custom",
                  modicon:
                    "https://test-lms.ratnasagar.com/theme/image.php/mb2aofk/scorm/1691127108/monologo",
                  indent: 0,
                  onclick: "",
                  afterlink: null,
                  customdata: '""',
                  completion: 0,
                  downloadcontent: "1",
                  noviewlink: false,
                  dates: [],
                  url: formName.url,
                  visible: "1",
                  visibleoncoursepage: 1,
                  uservisible: true,
                },
              ],
            },
          ],
        };
        setCoursecontent((prevState) => [...prevState, updatedMainData]);
      }
    } else if (formType === "child") {
      let childId = crypto.randomUUID();
      let moduleID = crypto.randomUUID();

      const updatedChild = {
        id: childId,
        name: formName.type,
        visible: "1",
        summary: "",
        summaryformat: "1",
        section: 2,
        hiddenbynumsections: 0,
        uservisible: true,
        modules: [
          {
            id: moduleID,
            name: formName.name,
            instance: "267",
            contextid: 19786,
            modname: "scorm",
            modplural: "Custom",
            modicon:
              "https://test-lms.ratnasagar.com/theme/image.php/mb2aofk/scorm/1691127108/monologo",
            indent: 0,
            onclick: "",
            afterlink: null,
            customdata: '""',
            completion: 0,
            downloadcontent: "1",
            noviewlink: false,
            dates: [],
            url: formName.url,
            visible: "1",
            visibleoncoursepage: 1,
            uservisible: true,
          },
        ],
      };

      const updeatedData = coursecontent.map((data) => {
        if (data.id === formName.childID) {
          let added = false;
          data.child?.map((item) => {
            if (item.name === formName.type) {
              item.modules.push(formName);
              added = true;
            }
            return 0;
          });
          if (!added) {
            data.child = [...data.child, updatedChild];
          }
        }
        return data;
      });
      setCoursecontent(updeatedData);
    }
  };

  const addModuleHandler = () => {
    let childId = crypto.randomUUID();

    handleClose();
    let updatedChild = {
      id: childId,
      name: formName.name,
      instance: "267",
      contextid: 19786,
      modname: "scorm",
      modplural: "Custom",
      modicon:
        "https://test-lms.ratnasagar.com/theme/image.php/mb2aofk/scorm/1691127108/monologo",
      indent: 0,
      onclick: "",
      afterlink: null,
      customdata: '""',
      completion: 0,
      downloadcontent: "1",
      noviewlink: false,
      dates: [],
      url: formName.url,
      visible: "1",
      visibleoncoursepage: 1,
      uservisible: true,
    };

    const updatedData = coursecontent.map((data) => {
      data.child?.map((childData) => {
        if (childData.id === formName.directChildID) {
          childData.modules.push(updatedChild);
        }
        return 0;
      });
      return 0;
    });

    setCoursecontent((prevState) => [...prevState, updatedData]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createModule = (val) => {
    return (
      <Grid container gap={2}>
        {val.modules.map((moduleValue) => {
          let imgName = moduleValue.name
            .replace(/[\s:,\d-_]/g, "")
            .toLowerCase();

          const extractFileUrls = (data) => {
            let fileUrls = [];
            if (
              data.modname === "url" &&
              data.contents &&
              data.contents.length > 0
            ) {
              data.contents.forEach((content) => {
                if (content.fileurl) {
                  fileUrls.push(content.fileurl);
                }
              });
            }
            return fileUrls;
          };
          const fileUrls = extractFileUrls(moduleValue);
          return (
            <AssetsBox key={moduleValue.id}>
              {
                <NavLink
                  to={{
                    pathname: `${
                      moduleValue.modplural === "Custom"
                        ? moduleValue.url
                        : "/GetCourseContent/modAccessInformation"
                    }`,
                    search: `?id=${
                      moduleValue.instance
                    }&courseID=${courseId}&title=${moduleValue.name}&modname=${
                      moduleValue.modname
                    }&classid=${classid}&url=${
                      fileUrls ? fileUrls : moduleValue.url
                    }`,
                  }}
                  className="courseTitle"
                >
                  <Box className="assetsBoxes">
                    <Box
                      className="titleFace"
                      sx={{
                        color: "textsColor.secColor",
                      }}
                    >
                      {moduleValue.name}
                    </Box>
                    <Box className={`imgBox`}>
                      <img
                        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/${imgName}.png`}
                        className="assetOverlayImg"
                        alt={imgName}
                      />
                    </Box>
                    <Box className={`overlayBox`}>
                      <Box className="topIcon">{moduleValue.name}</Box>
                    </Box>
                  </Box>
                </NavLink>
              }
            </AssetsBox>
          );
        })}
      </Grid>
    );
  };

  const createChild = (val) => {
    if (val.child && val.child.length > 0) {
      return val.child.map((valChild) => {
        return (
          <Accordion key={valChild.id} className="customAccordion">
            <AccordionChildItem
              valChild={valChild}
              courseID={courseId}
              classid={classid}
              activityDetails={activityDetails}
              teacherCompletion={teacherCompletion}
              syllabusCheck={syllabusCheck}
            />
            <AccordionDetails className="accordionDetail">
              {valChild.child && valChild.child.length > 0
                ? createChild(valChild)
                : createModule(valChild)}
            </AccordionDetails>
          </Accordion>
        );
      });
    } else if (val.modules && val.modules.length > 0) {
      return createModule(val);
    } else {
      console.log(val.name, "No assets");
    }
  };

  const accElements = coursecontent?.map((val, ind) => {
    if (
      coursecontent[ind]?.uservisible === true &&
      coursecontent[ind]?.name !== "General"
    ) {
      return (
        <Accordion key={val.name} className="customAccordion">
          <AccordionSummary className="accordionSumm">
            {val.name}
            {/* {activityDetails.includes(val.name) ? "dddd" : ""} */}
          </AccordionSummary>
          <AccordionDetails>{createChild(val)}</AccordionDetails>
        </Accordion>
      );
    } else {
      return null;
    }
  });

  return (
    <div className="dashboardMain">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item md={teacherMore ? "9" : "12"} sm={12}>
            <Card
              maxWidth="xl"
              sx={{
                mb: 4,
                backgroundColor: "cardColor.main",
                color: "cardColor.contrast",
              }}
              elevation={5}
              className="customCard"
            >
              <Typography
                className="title"
                variant="h5"
                px={2}
                py={1}
                sx={{ borderColor: "borderLine.main" }}
              >
                <Box className="titleHead">
                  <BackButton /> <b>{courseName}</b>
                  <Box>
                    {stdclass}
                    {section}
                  </Box>
                </Box>

                {userinfoObject[0].department === "Pedagogy" && (
                  <Link
                    rel="preload"
                    className="btns2 linkBtn primaryBtn"
                    variant="contained"
                    to={{
                      pathname: "/GetCourseContent/allStudentsReport",
                      search: `courseid=${courseId}&coursename=${courseName}&stdclass=${stdclass}&section=${section}`,
                    }}
                  >
                    Progress Report
                  </Link>
                )}

                {userinfoObject[0].department.toLowerCase() === "student" && (
                  <Link
                    rel="preload"
                    className="btns2 linkBtn primaryBtn"
                    variant="contained"
                    to={{
                      pathname: "/GetCourseContent/StudentReport",
                      search: `id=${stdClassInfo?.id}&studentName=${studentName}&courseid=${courseId}&coursename=${courseName}&stdclass=${stdClassInfo?.class}&section=${stdClassInfo?.section}`,
                    }}
                  >
                    View Progress
                  </Link>
                )}
              </Typography>

              <Box className="customCardContent" p={2}>
                <Scrollbar
                  style={{ width: "100%", height: 650 }}
                  className="customCardItem"
                >
                  {loading ? (
                    <Box textAlign={"center"}>
                      <CircularLoader />
                    </Box>
                  ) : coursecontent ? (
                    accElements
                  ) : (
                    <Box className="noData" sx={{ color: "red" }}>
                      Data not present for this course.
                    </Box>
                  )}
                </Scrollbar>
              </Box>
            </Card>
          </Grid>
          {teacherMore ? (
            <Grid item md={3} sm={12} className="courseContent">
              <CardPrimary curLanguage="Links" className="courseLink">
                <Box className="linkData">
                  <Link
                    rel="preload"
                    to="/add-certificate"
                    className="courseLinks"
                  >
                    <Box className="courseIcon">
                      <img
                        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/certificate.png`}
                        alt="certificateImg"
                        width={30}
                      />
                    </Box>
                    <Box sx={{ color: "textsColor.color" }}>
                      {curLanguage.createcertificate}
                    </Box>
                  </Link>
                  {isClassTeacher && (
                    <Link
                      rel="preload"
                      to="/attendance"
                      state={{
                        id: courseId,
                        section: section,
                        classid: classid,
                      }}
                      className="courseLinks"
                      style={{ color: "textsColor.color" }}
                    >
                      <Box className="courseIcon">
                        <img
                          src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance.svg`}
                          alt="certificateImg"
                          width={25}
                        />
                      </Box>
                      <Box sx={{ color: "textsColor.color" }}>
                        {curLanguage.attendance}
                      </Box>
                    </Link>
                  )}
                </Box>
              </CardPrimary>
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="formPopup"
        >
          <DialogTitle
            className="title"
            sx={{
              backgroundColor: "popup.titleHeaderColor",
              color: "popup.popupheadertextcolor",
            }}
          >
            <h3>Add Resource</h3>
          </DialogTitle>
          {formType === "main" && (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Name"
                      name="name"
                      value={formName.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formName.type}
                        name="type"
                        label="type"
                        onChange={handleChangeType}
                      >
                        <MenuItem value={"URL"}>URL</MenuItem>
                        <MenuItem value={"Books"}>Books</MenuItem>
                        <MenuItem value={"Videos"}>Videos</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {formName.type === "Books" && (
                    <Grid item xs={12}>
                      <input
                        accept=".pdf"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  )}

                  {formName.type === "Videos" && (
                    <Grid item xs={12}>
                      <input
                        accept="video/*"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  )}

                  {formName.type === "URL" && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Enter URL"
                        name="urlForm"
                        onChange={onURLChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      onClick={handleClose}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="btn"
                      sx={{ margin: "0 auto", display: "flex" }}
                    >
                      {curLanguage.save}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          )}

          {formType === "child" && (
            <form onSubmit={handleSubmit}>
              <DialogContent
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      required
                      label="Name"
                      name="name"
                      value={formName.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formName.type}
                        name="type"
                        label="type"
                        onChange={handleChangeType}
                      >
                        <MenuItem value={"URL"}>URL</MenuItem>
                        <MenuItem value={"Books"}>Books</MenuItem>
                        <MenuItem value={"Videos"}>Videos</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {formName.type === "Books" && (
                    <Grid item xs={12}>
                      <input
                        accept=".pdf"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  )}

                  {formName.type === "Videos" && (
                    <Grid item xs={12}>
                      <input
                        accept="video/*"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Grid>
                  )}

                  {formName.type === "URL" && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Enter URL"
                        name="urlForm"
                        onChange={onURLChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      onClick={addModuleHandler}
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ margin: "0 auto", display: "flex" }}
                      className="btn"
                    >
                      {curLanguage.save}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          )}
        </Dialog>
      </Container>
    </div>
  );
}
export default GetCourseContent;
