import { useNavigate } from "react-router-dom";
import "./BackBtn.scss";

const MainBackBtn = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard");
  };

  return (
    <>
    <img
      onClick={goBack}
      className="goBack"
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/backBtn.png`}
      alt="icon"
      width={45}
      style={{
        fontSize: "14px",
        minHeight: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  </>
  );
};

export default MainBackBtn;
