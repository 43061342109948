import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Assignments.module.scss";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../_Components/Utils/loader/Loader";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";

function Assignments() {
  const [viewAssessment, setViewAssessment] = useState([]);
  const courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const userInfoData = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userInfoData);

  const [subData, subDataShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigate();
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const stdId = JSON.parse(localStorage.getItem("classInfo"))?.classid;

  const rowDataUpdateHandler = (assignmentId, viewAssessment) => {
    navigation("/assignment", { state: { assignmentId, viewAssessment } });
  };

  const columnsStudent = [
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.scoid}
            variant="contained"
            sx={{
              backgroundColor: "secondaryColor.main",
              color: "secondaryColor.contrastText",
            }}
            onClick={() => openLinkInTab(cellValues.row.scoid, stdId)}
          >
            <Typography>Go</Typography>
          </Button>
        );
      },
    },
    { field: "assignmentName", headerName: "Assignment Name", width: 250 },
    { field: "assignmentType", headerName: "Assignment Type", width: 150 },
    { field: "assignedOn", headerName: "Assigned On", width: 180 },
    { field: "submitedOn", headerName: "Submited On", width: 180 },
    {
      field: "expectedcompletion",
      headerName: "Expected Completion",
      width: 180,
    },
    { field: "status", headerName: "Status", width: 150 },
    { field: "maxmarks", headerName: "Marks" },
    /* { field: "submissions", headerName: "Submissions" } */
  ];

  let columns;

  const columnsTeacher = [
    {
      field: "Status",
      headerName: "Action",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.scoid}
            state={cellValues.row.classid}
            variant="contained"
            sx={{
              backgroundColor: "secondaryColor.main",
              color: "secondaryColor.contrastText",
            }}
            onClick={() => openLinkInTab( cellValues.row.scoid, cellValues.row.classid)}
          >
            <Typography>Go</Typography>
          </Button>
        );
      },
    },
    { field: "assignmentName", headerName: "Assignment Name", width: 200 },
    { field: "assignmentType", headerName: "Assignment Type", width: 150 },
    { field: "courseSubject", headerName: "Subject", width: 200 },
    { field: "class", headerName: "Class", width: 120 },
    { field: "assignedOn", headerName: "Assigned On", width: 200 },
    { field: "maxmarks", headerName: "Marks" },
    { field: "assignSubmit", headerName: "Submit Count", width:110},
    { field: "questioncount", headerName: "Quest. Count", width:110 },
    // {
    //   field: "view",
    //   headerName: "View",
    //   width: 180,
    //   renderCell: (cellValues) => {
    //     return (
    //       <Button
    //         disabled = {cellValues.row.assignSubmit === 0}
    //         id={cellValues.row.scoid}
    //         variant="contained"
    //         sx={{
    //           backgroundColor: "secondaryColor.main",
    //           color: "secondaryColor.contrastText",
    //         }}
    //         onClick={() => {
    //           rowDataUpdateHandler(
    //             cellValues.row.assignmentName.split("Assignment")[1],
    //             viewAssessment.data
    //           );
    //         }}
    //       >
    //         <Typography>View Report</Typography>
    //       </Button>
    //     );
    //   },
    // },
  ];

  if (userinfoObject[0].department === "Pedagogy") {
    columns = columnsTeacher;
  } else {
    columns = columnsStudent;
  }

  function filterUniqueAssignmentIds(data) {
    
    const uniqueAssignments = {};
    data.forEach((item) => {
      const assignmentid = item.assignmentuniqueid;
      if (!uniqueAssignments[assignmentid]) {
        uniqueAssignments[assignmentid] = item;
      }
    });

    return Object.values(uniqueAssignments);
  }

  let modifiedViewAssessment = [];

  if (viewAssessment.data) {
    modifiedViewAssessment = filterUniqueAssignmentIds(viewAssessment.data);

  }

  useEffect(() => {
    if (userinfoObject[0].department === "Pedagogy") {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userinfoObject[0].id,
          schoolcode: userinfoObject[0].institution,
        })
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userinfoObject[0]?.id,
          classid: stdId,
        })
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  let rows;

  const rowsTeacher =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? modifiedViewAssessment.map((values, indx) => ({
          id: indx,
          courseid: values.courseid,
          assignmentName: `Assignment ${values.assignmentuniqueid}`,
          assignmentType: values.type === "cw" ? "Classwork" : "Homework",
          courseSubject: values.coursename,
          questionCount: values.questioncount,
          assignedOn: values.assignedon,
          expectedcompletion: values.expectedcompletion,
          submitedOn: values.timecreated,
          class: values.classsection,
          assignSubmit: values.assignment_submit_count,
          status: values.submitstatus ? "Submited" : "Not Attempted",
          maxmarks: values.maxmarks,
          questioncount: values.questioncount,
          scoid: values.scoid,
          classid: values.classid,
        }))
      : [];

  const rowsStudent =
    viewAssessment.data && Array.isArray(viewAssessment.data)
      ? viewAssessment.data.map((values, indx) => ({
       
          id: indx,
          courseid: values.courseid,
          assignmentName: `Assignment ${values.id}`,
          assignmentType: values.type === "cw" ? "Classwork" : "Homework",
          questionCount: values.questioncount,
          assignedOn: values.assignDate,
          expectedcompletion: values.expectedcompletion,
          submitedOn: values.timecreated,
          class: values.classsection,
          status: values.submitstatus ? "Submited" : "Not Attempted",
          maxmarks: values.maxmarks,
          questioncount: values.questioncount,
          scoid: values.scoid,
        }))
      : [];

  if (userinfoObject[0].department.toLowerCase() === "student") {
    rows = rowsStudent;
  } else {
    rows = rowsTeacher;
  }

  useEffect(() => {
    if (userinfoObject[0].department === "Pedagogy") {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByTeacherCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userinfoObject[0].id,
          schoolcode:userinfoObject[0].institution,
          // courseid: courseinfoObject[0].id,
        })
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsLoading(true);
      const v_assessmentURL = `${process.env.REACT_APP_nodewsPrefix}/getAssignedHWCWByStudentCourse`;
      axios
        .post(v_assessmentURL, {
          userid: userinfoObject[0].id,
          courseid: courseinfoObject[0].id,
          classid: stdId,
          schoolcode:userinfoObject[0].institution,
        })
        .then((response) => {
          setIsLoading(false);
          setViewAssessment(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  const setLink = (scoid, scormid, classid) => {
    let links;
    links = `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${scoid}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;
    return links;
  };

  const openLinkInTab = (scoid, classid) => {
    var scoid = scoid;
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${scoid}`)
      .then((response) => {
        window.open(setLink(scoid, response.data.id, classid), "");
      });
  };

  return (
    <Container maxWidth="xl">
      <Card
        className="customCard"
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item md={9} xs={9}>
              <Box className="titleHead">
                <MainBackBtn /> <b>Assignments</b>
              </Box>
            </Grid>
          </Grid>
        </Typography>

        {isLoading ? (
          <Loader />
        ) : (
          <Box className="customCardContent">
            {viewAssessment.data && viewAssessment.data.length > 0 ? (
              <div style={{ height: 680, width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <Box
                sx={{
                  color: "red",
                  padding: "10px 40px",
                  fontFamily: "Helvetica",
                }}
              >
                No Assingments found
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Container>
  );
}
export default Assignments;
