import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

const ChapterProgress = () => {
  const [studentCount, setStudentCount] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userInfo[0].id}&schoolcode=${userInfo[0].institution}`,{
          headers: {
            Authorization: `Bearer ${nToken}`
          }
        }).then((response) => {
        setStudentCount(response.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const data = studentCount?.map((data) => ({
    name: data.classsection,
    value: data.class_section_count,
  }));

  const option = {
    // title: {
    //   text: "Student count as their class",
    //   fontSize: 16,
    //   left: 15,
    //   top: 5,
    // },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}",
    },

    legend: {
      formatter: function (name) {
        return name.toUpperCase();
      },
      orient: "horizontal",
      left: 10,
      top: 0,
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      right: 10,
      top: 0,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        magicType: { show: false, type: ["bar", "stack"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Student Count",
        type: "pie",
        radius: "70%",
        center: ["50%", "50%"],
        selectedMode: "single",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          position: "inside",
          alignTo: "labelLine",
          bleedMargin: 5,
          margin: 0,
          fontSize: 16,
          fontWeight: "bold",
          show: true,
          formatter: " {c} ",
        },
        data: data,
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default ChapterProgress;
