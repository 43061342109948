import {
    Box,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DownloadImage } from "../../_Components/Common/UploadFile";

const DownloadAllAttendance = ({ studentInfo, attendanceinfo }) => {
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const financialYear = localStorage.getItem("financialYear");
  const stdIDS = studentInfo.map((student) => student.id);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const onHandleChange = (event) => {
    const { value } = event.target;
    setSelectedMonth(value);
  };

  const attendanceDownload = async () => {
    try {
      const siteInfoUrl = `${process.env.REACT_APP_nodewsPrefix}/bulkdataexportCSV?type=attendance&class=${studentInfo[0].class}&section=${studentInfo[0].section}&financial_year=${financialYear}&student_id=${stdIDS}&day=&month=${selectedMonth}`;

      const siteInfoResponse = await axios.get(siteInfoUrl);
      const blob = new Blob([siteInfoResponse.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "attendance.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading attendance:", error);
    }
    setOpen(false);
  };

 
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{padding: "0", minWidth: "20px" }}
      >
        <DownloadImage mssg="Download All Student Attendance"/>
      </Button>
     
      
      <CustomDialog isOpen={open} width={"xl"}  onClose={() => setOpen(false)}>
        <DialogContent>
          <Typography><b>
          Select a month to download the class attendance.</b>
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="department-label">Month</InputLabel>
            <Select
              labelId="department-label"
              id="department"
              name="month"
              label="month"
              value={selectedMonth}
              onChange={onHandleChange}
            >
              {monthNames.map((month, index) => (
                <MenuItem key={month} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box textAlign={"center"} mt={2}>
          <Button
            onClick={attendanceDownload}
            variant="contained"
            className="btn dialogBtn"
            title="Download Attendance"
          >
            Download Attendance
          </Button>
          </Box>
          
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default DownloadAllAttendance;
