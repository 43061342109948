import axios from "axios";
import { fabric } from "fabric";
import FontFaceObserver from 'fontfaceobserver';

let dummyNameText = "Your name here";
let currentCanvas = null;
let deleteBtn = null;
let options = {
    width: 0, height: 0,
    oldImage: null,
}
let getTextColor = null;
let setTextParams = null;

const canvasHelper = (canvas, width, height, func, textColorFun) => {
    options.width = width;
    options.height = height;
    setTextParams = func;
    getTextColor = textColorFun;

    fabric.Object.prototype.set({
        transparentCorners: false,
        borderColor: "#ff4e00",
        cornerColor: "#ff4e00",
        padding: '0',
        cornerStyle: 'square',
        cornerStrokeColor: 'red',
        /* cornerSize: 12, */
        borderDashArray: [10, 10]
    });

    deleteBtn = document.getElementById("deleteBtn");
    deleteBtn.addEventListener("click", onDelete);

    canvas.on("object:moving", function (e) {
        objectMoving(e);
    });

    canvas.on("selection:cleared", function (e) {
        if (e.deselected) {
            deselectObject();
        }

    });

    canvas.on("selection:created", function (e) {
        if (e.selected) {
            selectObject(e.selected);
        }
    });

    canvas.on("selection:updated", function (e) {
        if (e.selected) {
            selectObject(e.selected);
        }
    });

    currentCanvas = canvas;
}

const addBGImage = (templateid) => {

    currentCanvas.remove(options.oldImage);
    currentCanvas.renderAll();

    fabric.Image.fromURL(process.env.REACT_APP_nodewsPrefix + "/certificate-templates/" + templateid + ".jpg",
        function (img) {
            let ratioW = img.width / options.width;
            let ratioH = img.height / options.height;

            currentCanvas.setWidth(options.width);
            currentCanvas.setHeight(options.height);

            if (ratioH >= ratioW) {
                img.set({ scaleX: 1 / ratioW, scaleY: 1 / ratioW });
            } else {
                img.set({ scaleX: 1 / ratioH, scaleY: 1 / ratioH });
            }

            img.set({
                left: 0, top: 0, id: "backgroundImg",
                lockMovementX: true, lockMovementY: true,
                lockScalingX: true, lockScalingY: true,
                lockRotation: true, hasBorders: false,
                hasControls: false, selectable: false,
            }); /* perPixelTargetFind: true */

            let iWHratio = img.width / img.height;
            // let iRatioH = img.height / height;

            if (Math.floor(iWHratio) === 0) {
                currentCanvas.setWidth(305);
                let ratioW = img.width / 305;
                let ratioH = img.height / options.height;
                img.set({ scaleX: 1 / ratioW, scaleY: 1 / ratioH });
            }

            currentCanvas.add(img);
            currentCanvas.sendToBack(img);
            img.setCoords();
            options.oldImage = img
            currentCanvas.renderAll();
        }
    );
};

const addImage = (imgsrc, id) => {
    /* currentCanvas.remove(options.oldImage);
    currentCanvas.renderAll(); */

    fabric.Image.fromURL(imgsrc, function (img) {
        if (img.width > options.width || img.height > options.height) {
            img.set({
                left: 0, top: 0, id: id,
                scaleX: 0.25, scaleY: 0.25
            }); /* perPixelTargetFind: true */
        }
        let ratioW = img.width / options.width;
        let ratioH = img.height / options.height;

        /* if (ratioH >= ratioW) {
            img.set({ scaleX: 1 / ratioW, scaleY: 1 / ratioW });
        } else {
            img.set({ scaleX: 1 / ratioH, scaleY: 1 / ratioH });
        } */

        currentCanvas.add(img);
        // currentCanvas.sendToBack(img);
        img.setCoords();
        // options.oldImage = img
        currentCanvas.renderAll();
    }
    );
};

const addSvgImage = (svgsrc) => {
    axios.get(`${process.env.REACT_APP_nodewsPrefix}/getBadge?name=${svgsrc}`).then((response) => {
        fabric.loadSVGFromString(response.data, function (objects, option) {
            var loadedObject = fabric.util.groupSVGElements(objects, option);
            loadedObject.set({ scaleX: 0.5, scaleY: 0.5, top: 100, left: 100 });
            currentCanvas.add(loadedObject);
            currentCanvas.renderAll();
        })

    });

    /* fabric.loadSVGFromURL(svgsrc, function (objects, option) {
        var loadedObject = fabric.util.groupSVGElements(objects, option);
        loadedObject.set({scaleX:0.5, scaleY:0.5, top:100, left:100});
        currentCanvas.add(loadedObject);
        currentCanvas.renderAll();
    }) */
}

const addNameId = (isId) => {
    console.log(options.currentObject, isId);
    if (options.currentObject != null && options.currentObject.type == "textbox") {
        isId ? (options.currentObject.id = "name") : delete options.currentObject.id
    }
}

const addText = (text, type) => {
    // options.currentText == null
    if (type == "new" || type == "date") {
        var textField = new fabric.Textbox(text, { width: 110, left: 100, top: 100, fontSize: 24, tType: type });
        currentCanvas.add(textField);
        options.currentText = textField;
    } /* else {
        options.currentText.set('text', text);
    } */
    currentCanvas.setActiveObject(options.currentText);
    currentCanvas.renderAll();
}

const changeFontSize = (size) => {
    if (options.currentObject && options.currentObject.type === 'textbox') {
        // console.log(size, options.currentObject.type);
        options.currentObject.set({ fontSize: size })
    }

    currentCanvas.renderAll();
}

const changeFontFamily = (font) => {
    if (options.currentObject && options.currentObject.type === 'textbox') {
        console.log("change font family ABCD", "../../fonts/" + font + ".ttf");

        var myfont = new FontFaceObserver(font)
        myfont.load()
            .then(function () {
                // when font is loaded, use it.
                // canvas.getActiveObject().set("fontFamily", font);
                options.currentObject.set("fontFamily", font);
                currentCanvas.requestRenderAll();
            }).catch(function (e) {
                console.log(e)
                alert('font loading failed ' + font);
            });
    }
}

const changeTextColor = (color) => {
    if (options.currentObject && options.currentObject.type === 'textbox') {
        options.currentObject.set({ fill: color })
    }

    currentCanvas.renderAll();
}

const setTextAlignment = (align, a) => {
    if (options.currentObject && options.currentObject.type === 'textbox') {

        if (a === "align") {
            options.currentObject.set({ textAlign: align })
        } else {
            if (align == "bold") {
                options.currentObject.set({ fontWeight: options.currentObject.fontWeight === "bold" ? "normal" : "bold" })
            }
            if (align == "italic") {
                options.currentObject.set({ fontStyle: options.currentObject.fontStyle === "italic" ? "" : "italic" })
            }
            if (align == "underline") {
                options.currentObject.set({ underline: options.currentObject.underline ? false : true })
            }
            if (align == "linethrough") {
                options.currentObject.set({ linethrough: options.currentObject.linethrough ? false : true })
            }
            if (align == "overline") {
                options.currentObject.set({ overline: options.currentObject.overline ? false : true })
            }
        }
    }

    currentCanvas.renderAll();
}

const onDelete = () => {
    if (!options.currentObject) return;

    currentCanvas.remove(options.currentObject);
    currentCanvas.renderAll();
    options.currentObject = null;
    options.currentText = null;
    deleteBtn.style.display = "none";
    // document.getElementById("titleText").value = "";
}

const selectObject = (curObj) => {
    options.currentObject = curObj[0];
    deleteBtn.style.display = "none";

    currentCanvas.getObjects().map(function (o) {
        if (o.id == "name" && o.text.length == "") {
            o.text = dummyNameText;
        }
    });

    objectMoving();

    if (options.currentObject.type === "image") {
        options.currentObject.setControlVisible('mt', false);
        options.currentObject.setControlVisible('mb', false);
        options.currentObject.setControlVisible('ml', false);
        options.currentObject.setControlVisible('mr', false);
    }

    if (options.currentObject.type === 'textbox') {
        options.currentText = options.currentObject;
        document.getElementById("titleText").value = options.currentText.text;

        console.log(options.currentText.text, options.currentObject.id);

        setTextParams(options.currentObject.fontFamily, options.currentObject.fontSize);

        console.log(getTextColor, "xxd");
        // getTextColor()(options.currentObject.fill);
    }
}

const deselectObject = (curObj) => {
    if (options.currentObject && options.currentObject.id == "name" && options.currentObject.text.length == "") {
        options.currentObject.text = dummyNameText;
    }
    options.currentText = null;
    options.currentObject = null;
    deleteBtn.style.display = "none";
    document.getElementById("titleText").value = "";
    console.log('deselected...');
}

const objectMoving = () => {
    // console.log("moving", options.currentObject.left);
    if (options.currentObject.id === "name") {
        return
    }
    deleteBtn.style.position = "absolute";
    deleteBtn.style.display = "block";

    let leftPos = options.currentObject.left;
    let topPos = options.currentObject.top;

    deleteBtn.style.left = leftPos + (options.currentObject.width * options.currentObject.scaleX) + 15 + "px";
    deleteBtn.style.top = topPos - 10 + "px";
}

const addLoader = () => {
    currentCanvas.clear();

    /* var circle = new fabric.Circle({
        radius: 40,
        left: 0,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 4
    });

    currentCanvas.add(circle);
    currentCanvas.renderAll(); */

    var textField = new fabric.Text("Loading...");
    textField.set({ left: currentCanvas.width / 2 - textField.width / 2, top: currentCanvas.height / 2 - textField.height / 2 })
    currentCanvas.add(textField);
    currentCanvas.renderAll();
}

const loadJsonData = async (templateId) => {
    const nToken = localStorage.getItem("nToken");
    const jsonDataURL = `${process.env.REACT_APP_nodewsPrefix}/getCertificatesData`;
    addLoader();
    const response = await axios.post(jsonDataURL, {
        templateid: templateId
    }, {
        headers: {
            Authorization: `Bearer ${nToken}`
        }
    })

    currentCanvas.loadFromJSON(response.data, () => {
        // console.log(o.type);
    }, (o, object) => {
        // console.log(object.type, " *-*-*-*-*-*-*-");
        if (object.type === "image" && object.id === "backgroundImg") {
            let ratioW = object.width / options.width;
            let ratioH = object.height / options.height;

            currentCanvas.setWidth(options.width);
            currentCanvas.setHeight(options.height);

            if (ratioH >= ratioW) {
                object.set({ scaleX: 1 / ratioW, scaleY: 1 / ratioW });
            } else {
                object.set({ scaleX: 1 / ratioH, scaleY: 1 / ratioH });
            }

            object.set({
                left: 0, top: 0,
                lockMovementX: true, lockMovementY: true,
                lockScalingX: true, lockScalingY: true,
                lockRotation: true, hasBorders: false,
                hasControls: false, selectable: false,
            }); /* perPixelTargetFind: true */

            let iWHratio = object.width / object.height;
            // let iRatioH = img.height / height;

            if (Math.floor(iWHratio) === 0) {
                currentCanvas.setWidth(305);
                let ratioW = object.width / 305;
                let ratioH = object.height / options.height;
                object.set({ scaleX: 1 / ratioW, scaleY: 1 / ratioH });
            }
        }
    })
}

export { canvasHelper, addImage, addBGImage, addSvgImage, addText, addNameId, loadJsonData, changeFontSize, changeFontFamily, changeTextColor, setTextAlignment };