import { Box, Grid } from "@mui/material";

const ProfileBox = ({ children }) => {
  return (
    <Grid item xs={12} md={3} sm={6}>
      <Box className="profileData">{children}</Box>
    </Grid>
  );
};
export default ProfileBox;
