import { Box, Button, Card, List, ListItem, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { languages } from "../../App";
import { useContext } from "react";

function QuizSummaryList(props) {
  let curLanguage = useContext(languages);
  return (
    <>
      <List className="attemptUl">
        {props.attemptSummary?.questions.map((values) => (
          <ListItem key={values.slot} className="attemptLi">
            <NavLink
              to={`/assessmentAttempt`}
              state={{
                page: values.page,
                attemptid: props.attId,
                quizSummarycheck: "yes",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "cardColor.main",
                  color: "cardColor.contrast",
                }}
                elevation={5}
                className="attemptLiCard"
              >
                <Typography>{values.slot}</Typography>
                <Typography>{values.status}</Typography>
              </Card>
            </NavLink>
          </ListItem>
        ))}
      </List>

      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <Button
          color="buttons"
          variant="contained"
          size="small"
          onClick={props.sumbitHandler}
        >
          {curLanguage.submit}
        </Button>
      </Box>
    </>
  );
}

export default QuizSummaryList;
