import React, { useEffect, useState } from "react";

import BackButton from "../Common/BackBtn/BackBtn";
import './DateSheet.scss';
import { useContext } from "react";
import { languages } from "../../App";
import { Box, Card, Container, Typography } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import axios from "axios";

const DateSheet = () => {
  let curLanguage = useContext(languages);
  const [fullData, setFullData] = useState([]);
  const [firstRow, setFirstRow] = useState([]);
  const nToken = localStorage.getItem("nToken");

  function groupDataByDate(data, length) {
    // Find unique dates
    const uniqueDates = Array.from(new Set(data.map(item => item.timestart)));

    // Create an array to store the grouped data
    const groupedData = [];

    // Loop through each unique date
    uniqueDates.forEach(date => {
      // Filter data for the current date
      const dataForDate = data.filter(item => item.timestart === date);

      // Create an array to store the data objects for the current date
      const dataObjects = [];

      // Add an object with the date as the name
      dataObjects.push({ "name": date });

      // Add data objects for each class grade (1 and 2) or empty object if no data exists
      for (let i = 1; i <= length; i++) {
        const dataForClass = dataForDate.find(item => item.class === i);
        dataObjects.push(dataForClass || { name: "-" });
      }

      // Push the grouped data for the current date to the groupedData array
      groupedData.push({ "date": date, "data": dataObjects });
    });

    return groupedData.sort((a, b) => a.date - b.date);
  }




  function convertUnixTimestampToDate(timestamp) {
    if (typeof timestamp !== "string") {
      // Multiply by 1000 to convert to milliseconds (Unix timestamps are in seconds)
      let date = new Date(timestamp * 1000);

      // Format date
      let formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      });

      return `${formattedDate}`;
    } else {
      return timestamp
    }
  }


  const getDateSheetData = () => {
    axios.post(`${process.env.REACT_APP_nodewsPrefix}/viewExamSheet`, {
      "classname": []
    },{
      headers: {
        Authorization: `Bearer ${nToken}`
      }
    }).then(response => {
      let tempClass = response.data.exam_datesheet.map(data => {
        return data.class;
      })

      let tempUniqueClassCopy = [];

      

      let tempUniqueClass = [...new Set(tempClass)].sort((a, b) => a - b);
      for(let tuc=1; tuc<=tempUniqueClass[tempUniqueClass.length-1]; tuc++){
        tempUniqueClassCopy.push(tuc);
      }

      tempUniqueClassCopy.unshift('date')
      
      
      setFirstRow(tempUniqueClassCopy);

      let DatewiseData = groupDataByDate(response.data.exam_datesheet, tempUniqueClass[tempUniqueClass.length-1]);
      setFullData(DatewiseData);
    })
  }

  useEffect(() => {
    getDateSheetData();
  }, [])

  return (
    <Container maxWidth="xl">
      <Box className="dateSheetWrapper">
        <Card
          maxWidth="xl"
          sx={{
            p: 0,
            mb: 4,
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          elevation={5}
          className="customCard dateSheet"
        >
          <Typography
            className="title"
            variant="h5"
            px={2}
            py={1}
            sx={{ borderColor: "borderLine.main" }}
          >
            <Box className="titleHead">
              <BackButton />
              <b>Date Sheet</b>
            </Box>
          </Typography>

          <Box className="customCardContnet" px={3}>
            <Scrollbar style={{ width: "100%", height: 671 }}>
              <Box className="datesheetBody">
                <Box className="row">
                  
                  {firstRow.map((data,indx) => {
                    let romanGrade = ["Date","Class I", "Class II", "Class III", "Class IV", "Class V", "Class VI", "Class VII", "Class VIII", "Class IX", "Class X"];
                    return <Box className={typeof data !== "string" ? "rowData heading grade" : "rowData heading"} key={data}>
                      {romanGrade[indx]}
                    </Box>
                  })}
                </Box>

                {fullData.map(data => {
                  return (
                    <Box className="row">
                      {data.data.map(dataIn => {
                        return (
                          <Box class={typeof dataIn.name !== "string" ? "rowData heading" : "rowData subject"}>
                            {convertUnixTimestampToDate(dataIn.name)}
                          </Box>
                        );
                      })}
                    </Box>
                  )
                })}
              </Box>
            </Scrollbar>
          </Box>

        </Card>
      </Box>
    </Container>
  );
};

export default DateSheet;
