
const initialState = {
    data: [],
    myCourseData: []
}

export default function items(state = initialState, action) {
    console.log("reducer: ", action);
    switch (action.type) {
        case "ADD_DATA":
            return {
                ...state,
                data: action.data
            }
            break
        case "MYCOURSE_DATA":
            return {
                ...state,
                myCourseData: action.data
            }
            break
        default:
            return state

    }
}