import { Box, Card, Container, Typography } from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";

const ReportCard = () => {
  return (
    <Container maxWidth="xl">
      <Card
        maxWidth="xl"
        sx={{
          p: 0,
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{ borderColor: "borderLine.main" }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>Report Card</b>
          </Box>
        </Typography>
        <Box className="customCardContnet" p={2}>

        </Box>
      </Card>
    </Container>
  );
};

export default ReportCard;
