// CustomDialog.js

import { Dialog } from "@mui/material";
import React, { useState } from "react";

const CustomDialog = ({ isOpen, onClose, children, className, width, disableBackdropOption }) => {
  
  
  return (
    <Dialog open={isOpen} onClose={onClose} className={className} maxWidth={width}  fullWidth={true} disablebackdropclick={false}>
      {children}
    </Dialog>
  );
};

export default CustomDialog;
