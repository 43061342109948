import { Box, Card, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

const UserReportData = ({
  filterCourseID,
  filterClass,
  filterSection,
  filterStudent,
}) => {
  const [courseData, setCourseData] = useState(true);
  const [courseUsers, setCourseUsers] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/getActivityCompletion?course_id=${filterCourseID}&class=${filterClass}&section=${filterSection}&userid=${filterStudent}`;
        const response = await axios.get(reportDataURL, {
          headers: {
            Authorization: `Bearer ${nToken}`
          }
        });

        // Update courseData and courseUsers
        setCourseData(response.data.course_module);
        setCourseUsers(response.data.course_user);

        // Filter and process data based on selected filters
        const getActivityName = (id) => {
          let courseName;
          response.data.course_module.forEach((courseData) => {
            courseData.id.forEach((activityId) => {
              if (parseInt(activityId) === id) {
                courseName = courseData.name;
              }
            });
          });
          return courseName;
        };

        const studentFullData = response.data.course_user.map((studentData) => {
          let studentActivities = studentData.attendId.map(
            (studentAttemptID) => {
              return getActivityName(studentAttemptID);
            }
          );

          const counts = studentActivities.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
          }, {});

          const result = Object.entries(counts).map(([key, value]) => ({
            activity: key,
            count: value,
          }));

          return { ...studentData, count: result };
        });

        // Update progressData with filtered and processed data
        setProgressData(studentFullData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [filterCourseID, filterClass, filterSection, filterStudent]);

  const studentClickHandler = (id, studentName, courseName) => {
    navigate({
      pathname: "/GetCourseContent/StudentReport",
      search: `id=${id}&studentName=${studentName}&courseid=${filterCourseID}&coursename=${courseName}&stdclass=${filterClass}&section=${filterSection}`,
    });
    console.log(id, studentName);
  };

  // const filteredUsers = courseUsers.filter(
  //   (user) =>
  //     (user.class === filterClass && user.section === filterSection) ||
  //     user.subject === filterCourseID ||
  //     user.student === filterStudent
  // );
 

  const filteredUsers = courseUsers.filter((val) => {
    // Check if the department is student and institution matches
    let isStudent = []
    // If no class or section is selected, show all students
    if (!filterClass && !filterSection) {
      return isStudent;
    }
    // If only class is selected, filter students of that class
    if (filterClass && !filterSection) {
      return isStudent && val.class === filterClass;
    }
    // If both class and section are selected, filter students of that class & section
    if (filterClass && filterSection) {
      return (
        isStudent &&
        val.class === filterClass &&
        val.section === filterSection
      );
    }
    // If section is selected without class, filter students of that section
    if (!filterClass && filterSection) {
      return isStudent && val.section === filterSection;
    }
    return false;
  });
  
  return (
    <Container maxWidth="xl">
      {console.log(courseUsers, "progressData")}
      <Card
        sx={{
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
          position: "relative",
          marginTop: "16px",
          padding: "25px 15px",
        }}
        elevation={5}
        className="customCard"
      >
        <Scrollbar style={{ width: "100%", height: 500 }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={2}
            justifyContent={"center"}
          >
            {filteredUsers.length > 0 ? (
              filteredUsers.map((userData) => (
                <Box
                  key={userData.id}
                  className="cardBox"
                  maxWidth={"250px"}
                  minWidth={"230px"}
                  onClick={() =>
                    studentClickHandler(
                      userData.id,
                      `${userData.firstname} ${userData.lastname}`,
                      userData.course_name
                    )
                  }
                >
                  <Typography>
                    {userData.firstname}&nbsp;{userData.lastname}
                  </Typography>
                  <Typography>{userData.course_name}</Typography>
                  <Typography>
                    {userData.class}&nbsp;{userData.section}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography>
                No students found for selected class and section.
              </Typography>
            )}
          </Box>
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default UserReportData;
