import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";
import axios from "axios";
import { languages } from "../../App";
import "./StdCertificates.scss";
import { saveAs } from "file-saver";
import Loader from "../../_Components/Utils/loader/Loader";
import { Scrollbar } from "react-scrollbars-custom";
import { HiOutlineDownload } from "react-icons/hi";

function StdCertificates() {
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [certificates, setCertificates] = useState();
  const [fullViewCertificate, setFullViewCertificate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CertificateImg, setCertificateImg] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_nodewsPrefix}/getstdAllcertificate?studentid=${userinfo[0].id}`
      )
      .then((response) => {
        setIsLoading(false);
        console.log(response.data, "certificateData");
        setCertificates(response.data);
      });
  }, []);

  const fullViewCertificateHandler = (url) => {
    setOpen(true);
    setFullViewCertificate(true);
    setCertificateImg(url);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const downloadCertificate = () => {
    saveAs(CertificateImg, "certificate");
  };
  return (
    <Container maxWidth="xl">
      <Card
        sx={{
          mb: 4,
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
          position: "relative",
        }}
        elevation={5}
        className="customCard"
      >
        <Typography
          className="title"
          variant="h5"
          px={2}
          py={1}
          sx={{
            borderColor: "borderLine.main",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box className="titleHead">
            <BackButton />
            <b>{curLanguage.certificate}</b>
          </Box>
        </Typography>
        <Box p={2} className="customCardContent">
          <Scrollbar
            style={{ width: "100%", height: 671 }}
            className="customCardItem"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container spacing={2}>
                {certificates?.length ? (
                  certificates?.map((val, id) => {
                    return (
                      <Grid item md={3} key={id}>
                        <Card className="certificateCard">
                          <CardContent
                            className="certificateContent"
                            onClick={() =>
                              fullViewCertificateHandler(
                                process.env.REACT_APP_nodewsPrefix +
                                  val.certficateURL
                              )
                            }
                          >
                            <img
                              width="100%"
                              alt="Certificate"
                              src={
                                process.env.REACT_APP_nodewsPrefix +
                                val.certficateURL
                              }
                            />
                          </CardContent>
                          <CardActions sx={{ paddingTop: "0" }}>
                            <Box width={"100%"}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.primary"
                                >
                                  Earned for {val.subject} {val.type}
                                </Typography>
                                <Button
                                  size="small"
                                  onClick={downloadCertificate}
                                  startIcon={<HiOutlineDownload />}
                                >
                                  Download
                                </Button>
                              </Box>

                              <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                Created On: {val.timecreated}
                              </Typography>
                            </Box>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <Box sx={{ p: 4 }}>
                    <Typography variant="h4" textAlign={"center"}>
                      <b>You have not earned any certificates so far.</b>
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
          </Scrollbar>
        </Box>
      </Card>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent
          sx={{
            color: "popup.popupheadertextcolor",
          }}
        >
          <img
            src={CertificateImg}
            className="certificateImg"
            alt="Certificate"
            width={"100%"}
          />
          <Button
            variant="contained"
            onClick={downloadCertificate}
            sx={{ position: "absolute", right: 0, top: 0 }}
            startIcon={<HiOutlineDownload />}
          >
            Download
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
export default StdCertificates;
