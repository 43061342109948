import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import axios from "axios";

const ChapterCompletion = ({
  courseID,
  valChild,
  classid,
  isCourseCompleted,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYear = localStorage.getItem("financialYear");
  const userID = userInfo?.[0].id;
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(isCourseCompleted);
  const [initialValue, setInitialValue] = useState(isCourseCompleted);

  useEffect(() => {
    setInitialValue(isChecked);
  }, [isChecked]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    const numericValue = checked ? 1 : 0;

    axios
      .post(
        `${process.env.REACT_APP_nodewsPrefix}/addCourseSectionCompletion`,
        {
          courseid: courseID,
          classid: classid,
          course_sectionid: valChild.id,
          userid: userID,
          status: numericValue,
          financial_year: financialYear,
        }
      )
      .then((response) => {
        console.log("Data saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleResetCheckbox = () => {
    setIsChecked(initialValue);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          position: "absolute",
          right: 30,
          top: 6,
          display: "flex",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {isChecked ? (
          <>
            <Typography>
              <small>Chapter Completed</small>
            </Typography>
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/checkinner.png`}
              alt="checkBox"
              width={25}
            />
          </>
        ) : (
          <>
            <Typography>
              <small>Mark Chapter as Complete</small>
            </Typography>
            <img
              src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/checkouter.png`}
              alt="checkBox"
              width={25}
            />
          </>
        )}
      </Button>
      <CustomDialog
        isOpen={open}
        onClose={handleClose}
        className="syllabusCheck"
        width="xs"
        disableBackdropOption={false}
        onEnter={handleResetCheckbox}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Box className="completionBox">
            <Typography variant="h5">
              <b>
                Your chapter is {!isChecked ? "incomplete" : "complete"}.{" "}
                {isChecked
                  ? " Would you like to start again"
                  : "Have you completed it"}
                ?
              </b>
            </Typography>
            <Box className="completionBoxData">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className={isChecked ? "slide-right" : "slide-left"}
                  />
                }
                label={
                  <Box className="imgSec">
                    <img
                      src={
                        isChecked
                          ? `${process.env.REACT_APP_wsPrefix}/lms-assets/icons/cancel.svg`
                          : `${process.env.REACT_APP_wsPrefix}/lms-assets/icons/complete.svg`
                      }
                      alt={isChecked ? "Complete" : "Incomplete"}
                      width={60}
                      className="toggle-image"
                    />
                    <Typography>
                      {" "}
                      {isChecked
                        ? "Mark as  Incomplete"
                        : "Yes, mark as Complete"}
                    </Typography>
                  </Box>
                }
                className="toggle-label"
                onClick={handleClose}
              />
            </Box>
            <Button onClick={handleClose} className="btn closeRedBtn">
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default ChapterCompletion;
