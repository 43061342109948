import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { BsFiletypeCsv } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import "./AddAttendance.scss";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import InfoIcon from "../../_Components/Common/InfoIcon";
import { UploadImage } from "../../_Components/Common/UploadFile";

const UploadAttendance = ({ onAttendanceUpdate }) => {
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const onFileChange = (event) => {
    setFile({ selectedFile: event.target.files[0] });
  };
  console.log(file, "userinfo.id")
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("csvfile", file.selectedFile);
    formData.append("upload_name", "add_attendance");
    formData.append("added_by", userinfo[0].id);

    axios
      .post(`${process.env.REACT_APP_nodewsPrefix}/bulkdataimportCSV`, formData)
      .then((response) => {
        alert("Attendance updated successfully!!");
        e.target.reset();

        onAttendanceUpdate(response.data);
      });
    setOpen(false);
  };

  const downloadSampleCSV = async () => {
    const apiURL = `${process.env.REACT_APP_wsPrefix}/lms-assets/samples/addattendance.csv`;
    window.open(apiURL, "_blank");
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="uploadWrapper">
      <Button onClick={handleOpen} sx={{ padding: "0", minWidth: "20px" }}>
        <UploadImage mssg="Upload Attendance CSV File" />
      </Button>
      <CustomDialog isOpen={open} onClose={handleClose} width={"xl"}>
        <DialogTitle
          className="title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
          }}
        >
          Upload Attendance
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems={"center"}>
            <Grid item md={4}>
              <Typography fontWeight="bold">Example File:</Typography>
            </Grid>
            <Grid item md={8}>
              <Button
                onClick={downloadSampleCSV}
                className="dialogBtn"
                title="Download attendance template file"
              >
                <InfoIcon mssg="Attendance example csv file" />
              </Button>
            </Grid>
          </Grid>
          <Typography fontWeight="bold">Upload File:</Typography>
          <form className="attendanceUpload" onSubmit={handleSubmit}>
            <Input
              type="file"
              name="csvfile"
              onChange={onFileChange}
              inputProps={{ accept: ".csv" }}
              id="attendanceFile"
              style={{ display: "none" }}
            />
            <label htmlFor="attendanceFile" className="attendLabel">
              {!file && (
                <span>
                  <FiUpload /> Add attendance
                </span>
              )}
              {file && (
                <>
                  <BsFiletypeCsv />
                  {file.selectedFile.name}
                </>
              )}
            </label>
            <Box textAlign={"center"}>
              <Button
                type="submit"
                color="secondaryColor"
                variant="contained"
                className={` ${
                  file ? "btn custBtnAtt" : "disable btn custBtnAtt"
                }`}
              >
                Upload
              </Button>
            </Box>
          </form>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};

export default UploadAttendance;
