import ReactECharts from "echarts-for-react";

const AttendReport = ({ attendanceReport, echartsKey }) => {
  let option;
  option = {
    title: {
      text: "Attendance Report",
      left: "center",
      align: "center",
      fontSize: 16,
      top: 0,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: 10,
      bottom: 35,
      data: ["Present", "Absent", "Half Day"],
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      right: 48,
      top: "bottom",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: true },
        magicType: { show: false, type: ["bar", "stack"] },
        restore: { show: false },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        name: "Attendance",
        type: "pie",
        radius: "85%",
        center: ["50%", "54%"],
        selectedMode: "single",
        avoidLabelOverlap: false,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          position: "inner",
          margin: 20,
          fontSize: 16,
          fontWeight: "bold",
          show: true,
          formatter: "{b} {c}",
        },
        labelLine: {
          show: false,
        },
        color: ["#91cc75", "#ee6666", "#fac858"],
        data: [
          {
            value: attendanceReport.datasets[0].data[0],
            name: "Present",
          },
          {
            value: attendanceReport.datasets[0].data[1],
            name: "Absent",
            selected: true,
          },
          {
            value: attendanceReport.datasets[0].data[2],
            name: "Half Day",
          },
        ],
      },
    ],
  };
  return <ReactECharts key={echartsKey} option={option} />;
};

export default AttendReport;
