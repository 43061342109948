import { Box, Grid, Typography } from "@mui/material";
import styles from "./QuickLinks.module.scss";
import { Link, useLocation } from "react-router-dom";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

function QuickLinkItem({
  text,
  icon,
  url,
  assignmentCounts,
  viewAssessment,
  permissionName,
}) {
  const { permissions } = usePermissions();

  return (
    <>
      {(permissions[permissionName]?.read ||
        permissions[permissionName]?.write) && (
        <Link
          rel="preload"
          to={{
            pathname: url,
            state: {
              viewAssessment: viewAssessment,
            },
          }}
          className={styles.quickLink}
        >
          <Box
            className={`${styles["quickLinks--item"]}`}
            sx={{ flexDirection: "column" }}
          >
            <Box className={styles.imgBox}>
              <img
                src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/${icon}`}
                alt={text}
              />
              {assignmentCounts ? (
                <Typography className={styles["count"]}>
                  {assignmentCounts}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box className={styles["text"]}>{text}</Box>
            <Box className={styles.overlay}>
              <Box className={styles.overlayTxt}>
                <Typography className={styles.subject}>{text}</Typography>
              </Box>
            </Box>
          </Box>
        </Link>
      )}
    </>
  );
}

export default QuickLinkItem;
