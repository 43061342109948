import axios from "axios";
import dayjs from "dayjs";
import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Box,
  Checkbox,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import "./createCalendarEvent.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { languages } from "../../../App";
import ProcessText from "../../../_Components/Common/ProceessText";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../../_Components/Common/Buttons/CustomBtns";

const NewCalendarEvent = ({ dates, close, onEventUpdate }) => {
  let curLanguage = useContext(languages);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const courseDetails = JSON.parse(localStorage.getItem("courseDetails"));
  const token = localStorage.getItem("token");
  const nToken = localStorage.getItem("nToken");
  const date = dates;
  const d = new Date();
  let month = d.getMonth();
  let year = d.getFullYear();
  const [showGrids, setShowGrids] = useState(false);
  const [eventInfo, setEventInfo] = useState({
    title: "",
    description: "",
    subject: 0,
    section: 0,
    eventtype: "user",
    repeatCount: 0,
    date: "",
    courseid: 0,
    classsection: 0,
  });
  const [age, setAge] = useState();
  const [error, setError] = useState("");
  const [classSec, setClassSec] = useState([]);
  const [checked, setChecked] = useState({
    repeat: false,
    duration: "Withoutduration",
  });
  const fullDate = `${year}-${month + 1}-${date}T08:00`;
  const [eventDate, setEventDate] = useState({
    date: dayjs(fullDate).date(),
    month: dayjs(fullDate).month() + 1,
    year: dayjs(fullDate).year(),
    hour: dayjs(fullDate).hour(),
    minute: dayjs(fullDate).minute(),
  });

  const handleChange = (event) => {
    setAge(event.target.value);
    const { name, value } = event.target;
    setEventInfo({ ...eventInfo, [name]: value });
  };

  const handleEventChange = (event) => {
    setEventInfo({ ...eventInfo, eventtype: event.target.value });
    if (event.target.value === "user") {
      setShowGrids(false);
    } else {
      setShowGrids(true);
    }
  };
  const minDateTime = dayjs();
  const maxDateTime = dayjs().endOf("year");
  const handleDateChange = (e) => {
    setEventDate({
      date: e.$D,
      month: e.$M + 1,
      year: e.$y,
      hour: e.$H,
      minute: e.$m,
    });
  };

  const handleChangecheck = (e) => {
    if (e.target.value === "repeat") {
      setChecked({ repeat: e.target.checked, duration: e.target.value });
    } else {
      setChecked({ repeat: false, duration: e.target.value });
    }
  };
  const filterSubjects = (selectedClass) => {
    const filteredCourses = courseDetails.filter(
      (data) => ProcessText(data.shortname).number === selectedClass
    );
    console.log(filteredCourses, "filtersubjects");
    console.log(filteredCourses, "filtersubjects");

    return filteredCourses;
  };
  console.log("Selected Date:", dayjs(fullDate).date());
  console.log("Current Date:", dayjs().date());

  const submit = async (event, required) => {
    event.preventDefault();

    if (eventInfo.title.trim() === "") {
      setError("Please enter a title.");
      return;
    } else if (dayjs(fullDate).date() < dayjs().date()) {
      setError("Please choose today's date or any date after today.");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    const createEventURL = `${process.env.REACT_APP_nodewsPrefix}/createCalenderEvent`;
    try {
      const response = await axios.post(createEventURL, {
        title: eventInfo.title,
        description: eventInfo.description,
        repeats: eventInfo.repeatCount,
        courseid: eventInfo.subject,
        stdClass: eventInfo.classsection,
        section: eventInfo.section,
        eventtype: eventInfo.eventtype,
        year: eventDate.year,
        month: eventDate.month,
        date: eventDate.date,
        hour: eventDate.hour,
        minute: eventDate.minute,
        token: token,
        instituteid: userinfo[0]?.institution
      });

      setEventInfo(response.data);
      console.log(response.data, "responsedata");
      onEventUpdate(response.data, dayjs(fullDate).date());
   
      const { title } = eventInfo;
      alert(title + " Created");
      close(false);
    } catch (error) {
      console.error("Error while creating event:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`
          }
        })
        setClassSec(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box>
      <form onSubmit={submit} autoComplete="off">
        {error && (
          <Box className="errorBox">
            <Alert severity="error" className="errorContent">
              {error}
            </Alert>
          </Box>
        )}
        <Grid container spacing={2} className="eventcontent">
          <Grid item md={6}>
            <TextField
              required
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="title"
              autoFocus
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} className="customDate">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DateTimePicker"]}
                className="inputinner"
              >
                <DateTimePicker
                  value={dayjs(fullDate)}
                  label=""
                  onChange={handleDateChange}
                  name="date"
                  minDateTime={minDateTime}
                  maxDateTime={maxDateTime}
                  disablePast
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item md={12}>
            <Grid container alignItems={"center"}>
              <Grid item md={4}>
                <FormLabel component="legend">Create event for</FormLabel>
              </Grid>
              <Grid item md={8}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="eventtype"
                    name="eventtype"
                    value={eventInfo.eventtype}
                    onChange={handleEventChange}
                    defaultValue="Classes"
                    className="eventBtnsSec"
                  >
                    <Grid container>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="user"
                          control={<Radio />}
                          label="Self"
                        />
                      </Grid>
                      {userinfo[0]?.department.toLowerCase() === "pedagogy" && (
                        <Grid item md={6}>
                          <FormControlLabel
                            value="course"
                            control={<Radio />}
                            label="Class"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* Class and Sections start here */}
          {showGrids && (
            <>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.classsection}
                    name="classsection"
                    label="class"
                    onChange={handleChange}
                  >
                    {Array.from(
                      new Set(
                        classSec.map((obj) => obj.class).sort((a, b) => a - b)
                      )
                    ).map((data) => {
                      return (
                        <MenuItem key={data} value={data}>
                          Class {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    section
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.section}
                    name="section"
                    label="section"
                    onChange={handleChange}
                  >
                    {Array.from(
                      new Set(
                        classSec
                          .map((obj) => obj.section)
                          .sort((a, b) => a.localeCompare(b))
                      )
                    ).map((data) => {
                      return (
                        <MenuItem key={data} value={data}>
                          Section {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={eventInfo.subject}
                    name="subject"
                    label="Subject"
                    onChange={handleChange}
                  >
                    {filterSubjects(eventInfo.classsection).map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.fullname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          {/* Class and Sections end here */}
          <Grid item md={12}>
            <TextField
              id="outlined-multiline-flexible"
              label={curLanguage.description}
              multiline
              Rows={10}
              name="description"
              onChange={handleChange}
              className="customTxt"
            />
          </Grid>

          {/* until duration Section start here */}
          {/* 
          <Grid item md={12}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Withoutduration"
              name="radio-buttons-group"
            >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item md={8}>
                      <FormControlLabel
                        value="Withoutduration"
                        control={<Radio />}
                        label="Without duration"
                        onChange={handleChangecheck}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormControlLabel
                        value="until"
                        control={<Radio />}
                        onChange={handleChangecheck}
                        label="Until"
                      />
                    </Grid>
                  </Grid>
                  <Box className="customDate">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          name="calUntil"
                          disabled={checked.duration !== "until"}
                          value={dayjs(fullDate)}
                          label={curLanguage.eventDateUntil}
                          sx={{ mr: 0 }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box className="radioButtonDuration">
                    <FormControlLabel
                      value="minutes"
                      control={<Radio />}
                      label="Duration in minutes"
                      onChange={handleChangecheck}
                    />
                  </Box>

                  <Box className="minutesDuration inputbox">
                    <TextField
                      id="outlined-multiline-flexible"
                      label={curLanguage.minutes}
                      multiline
                      maxRows={1}
                      name="Minutes"
                      disabled={checked.duration !== "minutes"}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid> */}
          {/* until duration Section end here */}

          <Grid item md={12}>
            <Grid container>
              <Grid item md={4}>
                <FormControlLabel
                  control={
                    <Checkbox value="repeat" onChange={handleChangecheck} />
                  }
                  label={curLanguage.repeatThisEvent}
                />
              </Grid>
              <Grid item md={3}>
                <Box className="repeatEventCount  inputbox">
                  <TextField
                    id="outlined-multiline-flexible"
                    label={curLanguage.repeatWeeklyEvent}
                    multiline
                    maxRows={1}
                    name="repeatCount"
                    value={eventInfo.repeatCount}
                    disabled={!checked.repeat}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <DialogActions
          sx={{
            borderColor: "secondaryColorOutline.border",
            backgroundColor: "cardColor.main",
          }}
        >
          <PrimaryOutlineBtn onClick={() => close(false)}>
            {curLanguage.cancel}
          </PrimaryOutlineBtn>
          <PrimaryBgBtn type="submit"> {curLanguage.save}</PrimaryBgBtn>
        </DialogActions>
      </form>
    </Box>
  );
};
export default NewCalendarEvent;
