import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import "./recentCourse.scss";
import { useLocation } from "react-router";
import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Loader from "../../_Components/Utils/loader/Loader";
import { languages } from "../../App";
import CardPrimary from "../../_Components/Common/Cards/CardPrimary";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import ComingSoon from "../../_Components/Common/ComingSoon";

function MyCourse(props) {
  const [open, setOpen] = useState(false);
  let curLanguage = useContext(languages);
  const location = useLocation();
  const token = localStorage.getItem("token");
  const course = JSON.parse(localStorage.getItem("courseDetails"));
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  let classInfo = props.classInfo;
  const [isLoading, setIsLoading] = useState(true);
  // const baseURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_course_get_recent_courses&moodlewsrestformat=json`;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    /* axios.get(baseURL).then((response) => {
      setCourse(response.data);
    }); */

    setIsLoading(false);
  }, []);

  let coursesDiv;
  if (Array.isArray(course)) {
    coursesDiv = course?.map((item, ind) => {
      return (
        <NavLink
          key={ind}
          to={{
            pathname:
              item.summary.toLowerCase() === "coming soon" &&  userinfo[0]?.institution !== "school1"
                ? null
                : "/GetCourseContent",
            search: `?id=${item.id}&fullname=${item.fullname}&classid=${userinfo[0]?.customfields?.[0]?.value}`,
          }}
          onClick={() => {
            if (item.summary.toLowerCase() === "coming soon" && userinfo[0]?.institution !== "school1") {
              handleClickOpen();
            }
          }}
          className="navLinks"
        >
          <Box className="imgSec">
            <img
              src={
                item.overviewfiles[0]?.fileurl
                  ? item.overviewfiles[0]?.fileurl?.replace("/webservice", "")
                  : `${process.env.REACT_APP_wsPrefix}lms-assets/images/demo_course_icon.jpg`
              }
              alt="course-img"
            />
          </Box>
          <Box className="textArea">
            <Typography
              className="courseName"
              variant="h4"
              sx={{ color: "recentSub.main" }}
            >
              {item.fullname}
            </Typography>
          </Box>
          <Box className="overlay">
            <Box className="overlayTxt">
              <Typography className="subject" style={{ fontSize: "20px" }}>
                {" "}
                {item.fullname}
              </Typography>
            </Box>
          </Box>
        </NavLink>
      );
    });
  } else {
    coursesDiv = []; // or any other appropriate action
  }

  return (
    <CardPrimary curLanguage={curLanguage.mycourses}>
      {isLoading ? (
        <Loader />
      ) : coursesDiv && coursesDiv.length > 0 ? (
        coursesDiv
      ) : (
        <Box className="noCourse" sx={{ color: "red" }}>
          {curLanguage.norecentcourses}
        </Box>
      )}
      <CustomDialog isOpen={open} onClose={handleClose} className="" width="md">
        <ComingSoon />
      </CustomDialog>
    </CardPrimary>
  );
}
export default MyCourse;
