import { Box, Typography, Container, Card, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import "./TimeTable.scss";
import { timetable } from "./teacherTimeTbl";
import { Scrollbar } from "react-scrollbars-custom";
import BackButton from "../Common/BackBtn/BackBtn";
import CreatePDF from "../Common/pdf/CreatePDF";

const TeacherTimeTable = () => {
  const currentDate = new Date();
  const dayOfWeek = currentDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <Container maxWidth="xl">
      <Box className="teacherTimeTblWrapper">
        <Card
          maxWidth="xl"
          sx={{
            p: 0,
            mb: 4,
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          elevation={5}
          className="customCard teacherTimeTbl"
        >
          <Typography
            className="title"
            variant="h5"
            px={2}
            py={1}
            sx={{ borderColor: "borderLine.main" }}
          >
            <Box className="titleHead">
              <BackButton />
              <b>Teacher 's Time Table</b>
            </Box>

            <CreatePDF
              id="pdfcontainer"
              fileName="TimeTable"
              orientation={"landscape"}
              btnTitle={"Download PDF"}
              pdfHeading={"Time Table"}
            />
          </Typography>

          <Box id="pdfcontainer" className="customCardContent" px={3}>
            <Scrollbar style={{ width: "100%", height: 671 }}>
              <Box className="teacherTimeTblBody">
                {timetable.map((classData, i) => (
                  <Box className="teacherTimeTblContent" key={i}>
                    <Typography className="daysName">
                      {classData.day}
                    </Typography>
                    {classData.period.map((daysVal, j) => (
                      <Box className="teacherTimeTblInfo" key={j}>
                        <Typography className="periodTitle">
                          {j + 1 <= 4
                            ? `Period ${j + 1}`
                            : j + 1 === 5
                            ? "Interval"
                            : `Period ${j}`}
                        </Typography>
                        <Card
                          className="periodSubj"
                          sx={{
                            backgroundColor:
                              classData.day === daysOfWeek[dayOfWeek]
                                ? "yellowShade.bg"
                                : "blueShade.bg",
                          }}
                        >
                          <Typography
                            className="subjTitle"
                            sx={{
                              color:
                                classData.day === daysOfWeek[dayOfWeek]
                                  ? "yellowShade.color"
                                  : "blueShade.color",
                            }}
                          >
                            {daysVal.subject}
                          </Typography>
                          <Typography
                            className="teacherTitle"
                            sx={{
                              color:
                                classData.day === daysOfWeek[dayOfWeek]
                                  ? "yellowShade.color"
                                  : "blueShade.color",
                            }}
                          >
                            {daysVal.teacher}
                          </Typography>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Scrollbar>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default TeacherTimeTable;
