import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    themeColor: {
      bgColor: "#ffffff",
    },
    primary: {
      main: "#203bb3",
      contrastText: "#fff",
    },
    //color combinations
    redColor: {
      color: "#ee5558",
      background: "#f8dedf",
    },
    blueColor: {
      color: "#3279e5",
      background: "#d5edf9",
    },
    greenColor: {
      color: "#32c076",
      background: "#daeeef",
    },
    yellowColor: {
      color: "#edca4a",
      background: "#f9f2c4",
    },
    purpleColor: {
      color: "#9e4ee1",
      background: "#f6e4fe",
    },
    orangeColor: {
      color: "#f08e37",
      background: "#fce8cd",
    },

    secondaryColor: {
      main: "#204abd",
      contrastText: "#fff",
    },
    insideCard: {
      main: "#e2e8f6",
      borderColor: "#204abd",
    },
    secondaryColorOutline: {
      main: "#fff",
      border: "#204abd",
      contrastText: "#000",
    },
    categoryEventBtn: {
      main: "transparent",
      contrastText: "#ffffff",
    },
    userEventBtn: {
      main: "#fffa94",
      contrastText: "#000000",
    },
    teacherEventBtn: {
      main: "#d6d2ff",
      contrastText: "#000000",
    },
    buttons: {
      main: "#a4243b",
      contrastText: "#fff",
    },
    grayButton: {
      main: "#dedede",
      contrastText: "#373737",
    },
    extraButton: {
      main: "#39811e",
      contrastText: "#fff",
    },
    loginButton: {
      main: "#ff0000",
      contrastText: "#fff",
    },
    userPopup: {
      main: "#152332",
      contrastText: "#fff",
    },
    homeGreen: {
      main: "#39811e",
      contrastText: "#fff",
    },
    whiteBG: {
      main: "#fff",
    },
    closeButton: {
      main: "#000",
      contrastText: "#000",
    },

    profileButton: {
      main: "#204abd",
      contrastText: "#fff",
    },
    whiteButton: {
      main: "#fff",
      contrastText: "#fff",
    },
    FactsButton: {
      main: "#a4243b",
      contrastText: "#fff",
    },
    calendarWhiteButton: {
      main: "#fdfd7c",
      contrastText: "#fff",
    },
    calendarButton: {
      main: "transparent",
      contrastText: "#fff",
    },
    fackbookButton: {
      main: "#3b5998",
      contrastText: "#fff",
    },
    twitterButton: {
      main: "#1da1f2",
      contrastText: "#fff",
    },
    linkedinButton: {
      main: "#0077b5",
      contrastText: "#fff",
    },
    instagramButton: {
      main: "linear-gradient(180deg, #fff, #d1d1d1)", //linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)
      contrastText: "#fff",
    },
    youtubeButton: {
      main: "red",
      contrastText: "#fff",
    },
    resourceIcon: {
      main: "#ff4e00",
      contrastText: "#000",
    },
    switchButton: {
      main: "#ff4e00",
      contrastText: "#000",
    },
    headerBack: {
      main: "#fff",
    },
    borderLine: {
      main: "#204abd",
    },
    mainNavigator: {
      main: "#c0d9f3",
      mainGradient: "linear-gradient(180deg, #fff, #d1d1d1)",
    },
    calHeader: {
      mainGradient: "linear-gradient(90deg, #dbe5ff  -10%, #204abd  100%)",
    },
    calDate: {
      mainGradient: "linear-gradient(-17deg, #dbe5ff  -25%, #204abd  97%)",
    },
    adminBtnGradient: {
      gradient: "linear-gradient(180deg, #6581cf -10%, #022073 100%)",
    },
    footer: {
      bgColor: "#204abd",
    },
    darkblue: {
      color: "#ffffff",
    },
    sidebar: {
      color: "#ffffff",
      bgColor: "#204abd",
    },
    footerBg: {
      main: "#f2f9ff",
      contrastText: "#fff",
      bgColor: "#204abd",
    },
    black: {
      main: "#000",
    },
    popupbutton: {
      main: "#e7d6bad6",
      contrastText: "#000",
    },
    dashboard: {
      main: "#fff",
    },
    recentSub: {
      main: "#505050",
    },

    darkerBg: {
      bgColor: "#f4f4f4",
    },

    recent: {
      titleColor: "#2e2e2e",
      titlebgColor: "#a7a7a733",
      iconColor: "#000",
    },

    commonH: {
      titleColor: "#333",
    },

    subBoxes: {
      bgColor: "#fff",
      textColor: "#333",
    },

    calendar: {
      whiteColor: "#fff",
      blackColor: "#000",
      bgcolor: "#204abd",
      containerbgColor: "#dce7f0",
      outerbgColor: "#ebdabe",
      dayBgColor: "#df5c5c",
      borderColor: "204abd",
    },
    popup: {
      titleHeaderColor: "#204abd",
      popupFooter: "#204abd",
      popupheadertextcolor: "#ebebeb",
      popupclose: "#ebebeb",
    },
    assestBox: {
      bgcolor: "#fff",
    },
    resourcesBox: {
      bgcolor: "#eaeaea",
    },
    coursecontent: {
      titlebgColor: "#b90600",
      textColor: "#fff",
    },
    assessmentAttempt: {
      main: "#fff",
    },
    QuizBox: {
      bgColor: "#f4f4f4",
      textColor: "#333",
    },
    quizDescription: {
      TitlebgColor: "#204abd",
      titleTextColor: "#fff",
      CheckColor: "#adb1b5",
    },
    Quiznavigation: {
      main: "#fff",
      mainGradient: "linear-gradient(to bottom,#f0f0f0 0%,#fdfdfd 100%)",
      textColor: "#204abd",
      numTopbgColor: "#204abd",
      numBottombgColor: "#fff",
      quiznumText: "#fff",
    },
    Pagination: {
      main: "#a4243b",
      textColor: "#fff",
    },
    Snackbar: {
      textColors: "#000",
      main: "#000",
      textColor: "#FFFF00",
    },
    tooltip: {
      main: "#fff",
      backgroundColor: "#000",
    },
    //Colors
    blueShade: {
      bg: "#eef2ff",
      color: "#000",
    },
    yellowShade: {
      bg: "#fff1db",
      color: "#000",
    },
    orange: {
      bg: "#f9ece9",
      bgDark: "#ffe0d9",
      contrast: "#d74c29",
      text: "#d74c29",
    },
    purple: {
      bg: "#f0edf7",
      bgDark: "#eae2fc",
      contrast: "#6f47b8",
      text: "#6f47b8",
    },
    green: {
      bg: "#e5f4eb",
      bgDark: "#d4efdf",
      contrast: "#0a7c36",
      text: "#0a7c36",
    },
    grey: {
      bg: "#eeefef",
      bgDark: "#eae2fc",
      contrast: "#9a9a9b",
      text: "#9a9a9b",
    },
    linkTxt: {
      color: "#ed6c02",
    },
    table: {
      headerBg: "#eaedf5",
      contrast: "#647491",
    },
    //Admission Form Theme scss
    lightBg: {
      textColor: "#000000",
      bgColor: "#ebebeb",
    },
    icons: {
      textColor: "#c4c4c4",
      bgColor: "#eef6fe",
    },
    iconBg: {
      main: "#d7ecff",
      contrast: "#ebebeb",
      hover: "#dbebfb",
    },
    iconColor: {
      color: "#173b9d",
    },
    textsColor: {
      color: "#001011",
      secColor: "#3e3e3e",
    },
    labelColor: {
      color: "#666666",
    },
    cardColor: {
      main: "#ffffff",
      contrast: "#001011",
    },
    tab: {
      bgColor: "#204abd",
    },
    yellow: {
      color: "#ffb703",
    },

    //certificate theme color
    template: {
      bgColor: "#edf2ff",
      color: "#101010",
      borderColor: "#c0c0c0",
      headColor: "#e3ebff",
    },
    accordion: {
      bg: "#d4def7",
      color: "#001011",
    },
  },
});

const DefaultTheme = createTheme({
  palette: theme.palette,
  typography: {
    font: {
      fontsize10: "10px",
      fontsize20: "20px",
      fontsize30: "30px",
      fontsize40: "40px",
      fontsize50: "25px",
      fontsize60: "18px",
    },
  },
  components: {
    MuiGrid: {
      variants: [
        {
          props: { variant: "backgroundImage" },
          style: {
            backgroundImage:
              "url(http://moodle.ratnasagar.com/moodle/app-data/resources/images/bg-images/pattern_bg.webp)",
          },
        },
        {
          props: { variant: "gradient" },
          style: {
            backgroundImage: theme.palette.mainNavigator.mainGradient,
          },
        },
        {
          props: { variant: "gradient2" },
          style: {
            backgroundImage: theme.palette.calHeader.mainGradient,
          },
        },

        {
          props: { variant: "gradient3" },
          style: {
            backgroundImage: theme.palette.calDate.mainGradient,
          },
        },
        {
          props: { variant: "btnGradient" },
          style: {
            backgroundImage: theme.palette.adminBtnGradient.mainGradient,
          },
        },
        {
          props: { variant: "activeCalendar" },
          style: {
            background: "#204abd",
            border: "3px solid #113cb1",
            boxShadow:
              "0 3px 8px 0px rgba(0, 72 ,141 ,0.5), 0 3px 8px 0px rgba(0, 72 ,141 ,0.5) !important",
            color: "#fff",
          },
        },
        {
          props: { variant: "inactiveCalendar" },
          style: {
            background: "#ffffff",
            borderTop: "2px solid #204abd",
          },
        },
        {
          props: { variant: "gradient4" },
          style: {
            backgroundImage: theme.palette.Quiznavigation.mainGradient,
          },
        },
      ],
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },

      variants: [
        {
          props: { variant: "btngradient" },
          style: {
            backgroundImage:
              "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
            color: "#ffffff",
          },
        },
      ],
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-input": {
          color: "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#c4c4c4",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#a19d9d", // Set your desired border color on hover
        },
        "&::placeholder": {
          color: "#fff",
        },
        "&:hover ::placeholder": {
          color: "#eeeeee", // Set your desired border color on hover
        },
      },
      adornedEnd: {
        "& .MuiSvgIcon-root": {
          fill: "#fff", // Set the desired color for icons like dropdown and calendar
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#c4c4c4", // Set the desired color for the dropdown arrow
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#c4c4c4", // Set your desired label color for the dark theme
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#fff", // Set the desired color for the radio button when checked
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#c4c4c4", // Set the desired color for the radio button when checked
          },
        },
      },
    },
  },
});

export default DefaultTheme;
