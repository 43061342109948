import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MainCard from "../../_Components/Common/Cards/MainCard";

export default function UserManagement() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <MainCard cardTitle={"User Management"}>
      <Grid container spacing={2}>
        <Grid item>
          <Link
            rel="preload"
            to={{ pathname: "/settings" }}
            className="btn"
            style={{ textDecoration: "none" }}
          >
            Permission For School
          </Link>
        </Grid>
        {userInfo[0]?.department === "Developer" && (
          <Grid item>
            <Link
              rel="preload"
              to={{ pathname: "/devsettings" }}
              className="btn"
              style={{ textDecoration: "none" }}
            >
              Set Development Permission
            </Link>
          </Grid>
        )}
        <Grid item>
          <Link
            rel="preload"
            to={{ pathname: "/settings" }}
            className="btn"
            style={{ textDecoration: "none" }}
          >
            Assign Role
          </Link>
        </Grid>
      </Grid>
    </MainCard>
  );
}
