import { Box, Button, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { RiDeleteBin6Line, RiImageEditLine } from "react-icons/ri";
import CustomSnackBar from "../../_Components/Common/SnackBar/CustomSnackBar";
import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";

const ProfileImg = ({ isEditing, userId, onSave, profileImgInfo }) => {
  const [file, setFile] = useState(null);
  const [base64URL, setBase64URL] = useState("");
  const token = localStorage.getItem("token");
  const [draftItemId, setDraftItemId] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const profileImg = localStorage.getItem("profileImg");

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog2 = () => {
    setIsDialogOpen2(true);
  };

  const closeDialog2 = () => {
    setIsDialogOpen2(false);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];

    getBase64(selectedFile)
      .then((result) => {
        setFile(selectedFile);
        setBase64URL(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFile = () => {
    if (!file || !token) {
      console.log("No file or token available");
      return;
    }
    setIsDialogOpen(false);
    setOpenSnackbar(true);
    const formData = new FormData();
    formData.append("component", "user");
    formData.append("filearea", "draft");
    formData.append("itemid", "0");
    formData.append("filepath", "/");
    formData.append("filename", file.name);
    formData.append("filecontent", base64URL.split(",")[1]); // Remove base64 prefix
    formData.append("contextlevel", "user");
    formData.append("instanceid", userId);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_files_upload&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        setDraftItemId(response.data.itemid);
        console.log("File uploaded:", response.data);
        updateUserProfilePicture(response.data.itemid);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const updateUserProfilePicture = (draftItemId) => {
    if (!draftItemId || !userId || !token) {
      console.log("Missing required parameters");
      return;
    }

    const formData = new FormData();
    formData.append("draftitemid", draftItemId);
    formData.append("userid", userId);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_update_picture&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        console.log(
          "User profile picture updated:",
          response.data.profileimageurl
        );
        setProfileImage(response.data.profileimageurl);
        localStorage.setItem("profileImg", response.data.profileimageurl);
      })
      .catch((error) => {
        console.error("Error updating user profile picture:", error);
      });
  };

  const deleteUserProfilePicture = () => {
    const formData = new FormData();
    formData.append("draftitemid", 0);
    formData.append("userid", userId);
    formData.append("delete", 1);

    axios
      .post(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_update_picture&moodlewsrestformat=json`,
        formData
      )
      .then((response) => {
        console.log("User profile picture updated:", response.data);
      })
      .catch((error) => {
        console.error("Error updating user profile picture:", error);
      });
    localStorage.removeItem("profileImg");

    setIsDialogOpen2(false);
  };

  return (
    <Box>
      {isEditing ? (
        <Box className="profileImgSec">
          <img
            src={profileImg ? profileImg : profileImgInfo}
            alt="profileImg"
          />
        </Box>
      ) : (
        <>
          <Box className="profileImgSec editingImg">
            <img
              onClick={openDialog}
              src={
                profileImage
                  ? profileImage
                  : `${process.env.REACT_APP_wsPrefix}/lms-assets/images/defaultuser.png`
              }
              alt="profileImg"
            />
            <RiImageEditLine className="peIcon" onClick={openDialog} />
            <Box className="deleteIcon">
              <RiDeleteBin6Line onClick={openDialog2} />
            </Box>
          </Box>
          <CustomSnackBar
            message={"Profile Image updated successfully!"}
            open={openSnackbar}
            setOpen={setOpenSnackbar}
          />
          <CustomDialog isOpen={isDialogOpen} onClose={closeDialog} width="xs">
            <DialogContent>
              <Box className="photoFrame">
                <label htmlFor="upload-photo" className="custom-file-upload">
                  <RiImageEditLine className="peIcon" />
                  Upload here
                </label>
                <input
                  id="upload-photo"
                  type="file"
                  name="file"
                  onChange={handleFileInputChange}
                />
                <Box className="profileImgSec">
                  {base64URL && (
                    <img
                      src={base64URL}
                      alt="preview"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Box>
              </Box>

              <Box textAlign={"center"} mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={uploadFile}
                >
                  Upload
                </Button>
              </Box>
            </DialogContent>
          </CustomDialog>

          <CustomDialog
            isOpen={isDialogOpen2}
            onClose={closeDialog2}
            width="xs"
          >
            <DialogContent>
              <Typography>
                Are you sure you want to delete your profile picture?
              </Typography>

              <Box textAlign={"center"} mt={2}>
                <PrimaryBgBtn onClick={deleteUserProfilePicture}>
                  Delete
                </PrimaryBgBtn>
              </Box>
            </DialogContent>
          </CustomDialog>
        </>
      )}
    </Box>
  );
};

export default ProfileImg;
