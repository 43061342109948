import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import "./StudentAttendance.scss";

import axios from "axios";
import AttendanceCalendar from "./AttendanceCalendar/AttendanceCalendar";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";

const StudentRecord = ({ studentInfo, attendanceData }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const financialYear = localStorage.getItem("financialYear");
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleOpenDialog = (studentdata, studentIndex) => {
    const selectedStudentData = studentdata.find(
      (student) => student.id === studentIndex
    );
    setSelectedStudent(selectedStudentData);
    setOpenDialog(true);
  };

  {console.log(attendanceData, "studentInfostudentRecord")}
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonthDateColumns = (month) => {
    const daysInMonth = new Date(currentYear, month, 0).getDate();
    const dateColumns = [];

    for (let day = 1; day <= daysInMonth; day++) {
      dateColumns.push({
        field: `day_${day}`,
        headerName: `${day}`,
        width: 80,
        renderCell: (params) => params.row[`day_${day}`] || "",
      });
    }

    return dateColumns;
  };

  useEffect(() => {
    const filteredStudents = Array.isArray(studentInfo)
      ? studentInfo.filter((datas) => datas.department.toLowerCase() === "student")
      : [];
    const updatedRows = studentInfo.map((datas) => {
      const studentRow = {
        id: datas.id,
        fullname: datas.fullname || `${datas.firstname} ${datas.lastname}`,
        className: "customFullNameClass",
      };
      const currentMonths = monthNames[currentMonth - 1];
      const studentAttendanceData = attendanceData?.[datas.id];
      if (studentAttendanceData) {
        const statuses = studentAttendanceData[currentMonths];
        if (statuses && Array.isArray(statuses)) {
          statuses.forEach((status, index) => {
            studentRow[`day_${index + 1}`] = status;
          });
        }
      }

      return studentRow;
    });
    if (updatedRows.length === 0) {
      updatedRows.push({ id: "N/A", fullname: "Record not Found", className: "customFullNameClass" });
    }
    setRows(updatedRows);
  }, [studentInfo, attendanceData, currentMonth, currentYear]);

  const attendanceDownload = async () => {
    try {
      const siteInfoUrl = `${process.env.REACT_APP_nodewsPrefix}/bulkdataexportCSV?type=attendance&class=${studentInfo[0].class}&section=${studentInfo[0].section}&financial_year=${financialYear}&student_id=${selectedStudent?.id}&day=&month=${currentMonth}`;

      const siteInfoResponse = await axios.get(siteInfoUrl);
      const blob = new Blob([siteInfoResponse.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "attendance.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading attendance:", error);
    }
  };

  const handleMonthChange = (newMonth) => {
    let newYear = currentYear;
    if (newMonth > 12) {
      newMonth = 1;
      newYear++;
    } else if (newMonth < 1) {
      newMonth = 12;
      newYear--;
    }
    const currentDate = new Date();
    if (
      newYear > currentDate.getFullYear() ||
      (newYear === currentDate.getFullYear() &&
        newMonth > currentDate.getMonth() + 1)
    ) {
      return;
    }

    setCurrentMonth(newMonth);
    setCurrentYear(newYear);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    ...getMonthDateColumns(currentMonth),
  ];

  const MonthHeader = ({ currentMonth, currentYear, onMonthChange }) => {
    const prevMonthVal = monthNames[currentMonth - 1] === monthNames[0];
    const nextMonthVal = monthNames[currentMonth - 1];
    console.log(monthNames[currentMonth], "nextMonthVal");

    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mb={1}
        className="monthDetail"
      >
        {console.log(monthNames[0], "monthname")}
        {console.log(monthNames[currentMonth - 1], "monthname")}
        <Button
          onClick={() => onMonthChange(currentMonth - 1)}
          className={`${
            prevMonthVal
              ? "iconOutline monthIcon disable"
              : "iconOutline monthIcon"
          }`}
        >
          <IoChevronBack />
        </Button>
        <Typography variant="h5" className="monthTitle">
          <b>{`${monthNames[currentMonth - 1]} `}</b>
        </Typography>

        <Button
          onClick={() => onMonthChange(currentMonth + 1)}
          className={`${
            monthNames[currentMonth]
              ? " iconOutline monthIcon "
              : "iconOutline monthIcon "
          }`}
        >
          <IoChevronForward />
        </Button>
      </Box>
    );
  };
  return (
    <Box className="stdRecord">
      <MonthHeader
        currentMonth={currentMonth}
        currentYear={currentYear}
        onMonthChange={handleMonthChange}
      />
      <Box style={{ maxHeight: 800, minHeight: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableColumnMenu
          onRowSelectionModelChange={(selectionModel) => {
            const selectedStudentIndex = selectionModel[0];
            const selectedStudentId = rows;
            setRowSelectionModel(selectionModel);
            handleOpenDialog(selectedStudentId, selectedStudentIndex);
          }}
          rowSelectionModel={rowSelectionModel}
          getRowClassName={(params) => params.row.className}
        />
      </Box>
      <CustomDialog
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        className="customModal"
        width="xl"
      >
        <Box sx={{ position: "absolute", right: 10, top: 5 }}>
          <Button
            onClick={attendanceDownload}
            variant="contained"
            className="btn dialogBtn"
            sx={{ padding: "10px" }}
          >
            Download Attendance
          </Button>
        </Box>
        <DialogContent>
          {attendanceData ? (
            <AttendanceCalendar
              attendanceData={attendanceData}
              stdName={selectedStudent?.fullname}
              stdId={selectedStudent?.id}
            />
          ): "No data"}
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};

export default StudentRecord;
