import {
  Box,
  Button,
  Card,
  Container,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./Notes.scss";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState, useContext, useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { languages } from "../../App";
import axios from "axios";
import MainBackBtn from "../../_Components/Common/BackBtn/MainBackBtn";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

const MyNotes = () => {
  let curLanguage = useContext(languages);
  const nToken = localStorage.getItem("nToken");
  const userInfoData = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userInfoData);
  const [rows, setRows] = useState([]);
  const [dltPopUp, setDltPopUp] = useState(false);
  const [eventData, setEventData] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [addNoteState, setAddNoteState] = useState(false);
  const [addNotes, setAddNotes] = useState({
    title: "",
    text: "",
  });
  const [state, setState] = useState({
    isEditing: true,
    currentID: null,
    actualID: null,
  });

  const viewHandleClose = () => {
    setViewOpen(false);
    setAddNoteState(false);
    setEventData({});
    setState((prev) => ({
      ...prev,
      isEditing: true,
    }));
  };

  const handleChange = (e) => {
    if (!state.isEditing && addNoteState) {
      if (e.target.parentNode.parentNode.getAttribute("usedFor") === "title") {
        setAddNotes((prev) => ({
          ...prev,
          title: e.target.value,
        }));
      } else {
        setAddNotes((prev) => ({
          ...prev,
          text: e.target.value,
        }));
      }
      return;
    }

    if (e.target.parentNode.parentNode.getAttribute("usedFor") === "title") {
      rows[e.target.id].title = e.target.value;
    } else {
      rows[e.target.id].text = e.target.value;
    }

    setState((prev) => ({
      ...prev,
      rows,
    }));
  };

  const editHandler = async (e) => {
    if (!state.isEditing && addNoteState) {
      if (!addNotes.title && !addNotes.text) {
        alert("Enter both the title and description to proceed with saving");
        return;
      }

      await axios.post(`${process.env.REACT_APP_nodewsPrefix}/createNote`, {
        userid: userinfoObject[0].id,
        modname: "app",
        contextid: 0,
        title: addNotes.title,
        text: addNotes.text,
        otherinfo: "",
      }, {
        headers: {
          Authorization: `Bearer ${nToken}`
        }
      })

      setAddNoteState(false);
      setViewOpen(false);
      setAddNotes("");
      return;
    }

    if (!state.isEditing) {
      if (!rows[state.currentID]?.title || !rows[state.currentID]?.text) {
        alert("Enter both the title and description to proceed with saving");
        return;
      }

      await axios.post(`${process.env.REACT_APP_nodewsPrefix}/updateNote`, {
        id: e.currentTarget.id,
        userid: userinfoObject[0].id,
        title: rows[state.currentID]?.title,
        text: rows[state.currentID]?.text,
      }, {
        headers: {
          Authorization: `Bearer ${nToken}`
        }
      })

      setState((prev) => ({
        ...prev,
        isEditing: true,
      }));
      setAddNoteState(false);
      setViewOpen(false);
      setEventData("");

      setAddNotes("");
    }

    setState((prev) => ({
      ...prev,
      isEditing: false,
    }));
  };

  const onViewClicked = (e, cellValues) => {
    setState((prev) => ({
      ...prev,
      isEditing: true,
      currentID: cellValues.row.tempID,
    }));
    setEventData(cellValues);
    setViewOpen(true);
  };

  const onDeleteClicked = (id, tempID) => {
    console.log("delete note ", id, tempID);
    setDltPopUp(true);
    setState((prev) => ({
      ...prev,
      currentID: tempID,
      actualID: id,
    }));
  };

  const deleteNoteHandler = () => {
    axios.post(`${process.env.REACT_APP_nodewsPrefix}/deleteNote`, {
      id: state.actualID,
    }, {
      headers: {
        Authorization: `Bearer ${nToken}`
      }
    })
      .then((response) => {
        setDltPopUp(false);
        let arr = [...rows];
        arr.splice(state.currentID, 1);
        setState(arr);
      });
  };

  const addNoteHandler = () => {
    setAddNoteState(true);
    setViewOpen(true);
    setState((prev) => ({
      ...prev,
      isEditing: false,
    }));
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_nodewsPrefix}/getAllnotesByid`, {
        userid: userinfoObject[0].id,
      }, {
        headers: {
          Authorization: `Bearer ${nToken}`
        }
      })
      .then((response) => {
        setRows(response.data.note);
      });
  }, [dltPopUp, viewOpen]);

  const sortedData = [...rows].sort((a, b) => {
    const latestDateA = Math.max(
      new Date(a.timecreated),
      new Date(a.lastedited)
    );
    const latestDateB = Math.max(
      new Date(b.timecreated),
      new Date(b.lastedited)
    );
    return latestDateB - latestDateA; // Sort in descending order (newest first)
  });
  
  const columns = [
    {
      field: "Status",
      headerName: "",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button
            id={cellValues.row.tempID}
            variant="contained"
            sx={{ backgroundColor: "iconBg.main", color: "iconColor.color" }}
            onClick={(e) => {
              onViewClicked(e, cellValues);
            }}
            className="reviewButton"
          >
            <Typography sx={{ color: "iconColor.color" }}>View</Typography>
          </Button>
        );
      },
    },
    {
      field: "title",
      headerName: curLanguage.title,
      type: "string",
      width: 200,
    },
    {
      field: "text",
      headerName: curLanguage.description,
      type: "string",
      width: 400,
    },
    {
      field: "timecreated",
      headerName: curLanguage.createdOn,
      type: "singleSelect",
      width: 180,
    },
    {
      field: "lastedited",
      headerName: curLanguage.modifiedOn,
      type: "singleSelect",
      width: 180,
    },
    {
      field: "Actions",
      headerName: curLanguage.action,
      type: "actions",
      width: 120,
      cellClassName: "customCell",
      getActions: (params) => [
        <Button
          variant="contained"
          sx={{ backgroundColor: "iconBg.main", color: "iconBg.contrast" }}
          className="circleBtn"
          key={params.id}
          onClick={() => onDeleteClicked(params.id, params.row.tempID)}
        >
          <RiDeleteBin6Line />
        </Button>,
      ],
    },
  ];

  return (
    <>
      <>
        <Dialog
          open={dltPopUp}
          onClose={() => setDltPopUp(!dltPopUp)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              backgroundColor: "popup.titleHeaderColor",
              color: "popup.popupheadertextcolor",
              mb: 4,
            }}
          >
            {"Delete note?"}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
            }}
          >
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "cardColor.contrast" }}
            >
              Are you sure you want to delete this note? This action cannot be
              undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              borderColor: "secondaryColorOutline.border",
              backgroundColor: "cardColor.main",
            }}
          >
            <PrimaryBgBtn onClick={deleteNoteHandler} autoFocus>
              Yes
            </PrimaryBgBtn>
            <PrimaryOutlineBtn onClick={() => setDltPopUp(false)}>
              No
            </PrimaryOutlineBtn>
          </DialogActions>
        </Dialog>

        <Dialog
          open={viewOpen}
          onClose={viewHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          width="md"
        >
          <DialogTitle
            id="alert-dialog-title"
            className={`title ${"noteHead"}`}
            sx={{
              backgroundColor: "popup.titleHeaderColor",
              color: "popup.popupheadertextcolor",
            }}
          >
            <TextField
              required
              label={state.isEditing ? "" : "Enter Title"}
              variant="outlined"
              fullWidth
              disabled={state.isEditing}
              usedFor="title"
              id={eventData.row?.tempID}
              value={eventData.row && rows[eventData.row.tempID]?.title}
              onChange={handleChange}
            />
            {/* <Popupclose close={viewHandleClose} /> */}
          </DialogTitle>
          <DialogContent
            className="noteContent"
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
            }}
          >
            <TextField
              required
              label={state.isEditing ? "" : "Enter description"}
              variant="outlined"
              fullWidth
              multiline
              rows={15}
              disabled={state.isEditing}
              usedFor="text"
              placeholder="Enter description"
              id={eventData.row?.tempID}
              value={eventData.row && rows[eventData.row.tempID].text}
              onChange={handleChange}
            />

            <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
              <InputLabel id="Created" className="modalLabel">
                <Typography>
                  {eventData.row && rows[eventData.row.tempID].timecreated
                    ? "Created On:"
                    : ""}{" "}
                  &nbsp;
                  <b>
                    {eventData.row && rows[eventData.row.tempID].timecreated}
                  </b>
                </Typography>
              </InputLabel>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", m: 1 }}>
              <InputLabel id="Created" className="modalLabel">
                <Typography>
                  {eventData.row && rows[eventData.row.tempID].lastedited
                    ? "Last Modified:"
                    : ""}
                  &nbsp;
                  <b>
                    {eventData.row && rows[eventData.row.tempID].lastedited}
                  </b>
                </Typography>
              </InputLabel>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              borderColor: "secondaryColorOutline.border",
              backgroundColor: "cardColor.main",
            }}
          >
            <PrimaryBgBtn id={eventData.row?.id} onClick={editHandler}>
              {!state.isEditing ? "Save" : "Edit"}
            </PrimaryBgBtn>
          </DialogActions>
        </Dialog>
      </>
      <div>
        <Container maxWidth="xl">
          <Card
            maxWidth="xl"
            sx={{
              p: 0,
              mb: 4,
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
            }}
            elevation={5}
            className="customCard"
          >
            <Typography
              className="title"
              variant="h5"
              px={2}
              py={1}
              sx={{ borderColor: "borderLine.main" }}
            >
              <Box className="titleHead">
                <MainBackBtn />
                <b>{curLanguage.mynotes}</b>
              </Box>
              <Button variant="contained" onClick={addNoteHandler}>
                Add notes
              </Button>
            </Typography>
            <Box className="customCardContnet">
              <DataGrid
                columns={columns}
                rows={rows}
                autoHeight={false}
                /* checkboxSelection */
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                classes={{
                  virtualScroller: "customVirtualScroller",
                }}
                sx={{
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Box>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default MyNotes;
