import React from "react";
import { Grid, Box } from "@mui/material";
import './Quiznavigation.scss'
import TourIcon from '@mui/icons-material/Tour';

const Quiznavigation = (props) => {
    console.log("qprops", props)
    let bgColor = "white";
    const changeQuestion = () => {
        props.setPageNo(props.qNo)
        props.setAttSaved(prevState => prevState + 1)
    }

    if (props.from === "Review") {
        if (props.status === "Correct") {
            bgColor = "#47a025";
        } else if (props.status === "Incorrect") {
            bgColor = "red";
        }
    }

    const navClickedHandler = e => {
        e.stopPropagation();
        if (document.getElementById(`Qno-${parseInt(e.target.innerHTML) - 1}`) == null) {
            document.getElementById("Qno-1").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        } else {
            document.getElementById(`Qno-${parseInt(e.target.innerHTML) - 1}`).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }

    }

    let navClassesStudent = "";

    if(props.from === "Review"){
        console.log("Review +",props);
        if(props.flag){
            navClassesStudent += " isFlaged";
        }
        if(props.status === "Correct"){
            navClassesStudent += " isAttempted";
        }
        if(props.status === "Incorrect"){
            navClassesStudent += " isIncorrect";
        }        
    }else{
        if (props.qNo === props.activeQ) {
            navClassesStudent += "activeQuestion";
        }    
        if (props.isFlaged) {
            navClassesStudent += " isFlaged";
        }    
        if (props.isAttempted === "Answer saved") {
            navClassesStudent += " isAttempted";
        }
    }
   

    return (
        <>
            {props.from === "Review" ?
                <button className={`quizNavNumber ${navClassesStudent}`} onClick={navClickedHandler}>
                    {props.qNo + 1}
                </button>
               /*  <Grid item xs={1} className={`quizNavNumber`} sx={{ color: 'Quiznavigation.quiznumText', bgcolor: 'Quiznavigation.numTopbgColor' }}onClick={navClickedHandler} id={`Qno${props.qNo}`}>
                    <Box className="innerBoxF" sx={{backgroundColor:bgColor}}>{props.flag ? <TourIcon sx={{color:"gray !important"}}/> : " "}</Box>{props.qNo+1}
                </Grid> */ :

                <button className={`quizNavNumber ${navClassesStudent}`} onClick={changeQuestion}>
                    {props.qNo + 1}
                </button>
            }
        </>
    )
}

export default Quiznavigation