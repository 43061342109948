import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./MyClasses.scss";
import { languages } from "../../App";
import Loader from "../../_Components/Utils/loader/Loader";
import axios from "axios";

const MyClasses = ({  coursesName }) => {
  let curLanguage = useContext(languages);
  const courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoading, setIsLoading] = useState(true);
  const [enroledClass, setEnroledClass] = useState([]);
  const nToken = localStorage.getItem("nToken");

  useEffect(() => {
    const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
    axios.get(baseURL, {
      headers: {
        Authorization: `Bearer ${nToken}`
      }
    }).then((response) => {
      setIsLoading(false);
      sortData(response.data.rows);
    });
  }, []);

  const sortData = (data) => {
    const sortedData = [...data].sort((a, b) => a.id - b.id);
    setEnroledClass(sortedData)
  }
  
  const getName = (item) => {
    let name = "";
    let imgsrc = "";

    courseinfoObject?.map((val) => {
      if (val.id === item.courseid) {
        name = val.fullname;
        imgsrc = val.overviewfiles[0]?.fileurl.replace("/webservice", "");
      }
    });

    return { name: name, image: imgsrc };
  };

  let courses = enroledClass.map((item) => {
    return (
      <NavLink
        key={item.id}
        to={{
          pathname: "/GetCourseContent",
          search: `?id=${item.courseid}&fullname=${encodeURIComponent(getName(item).name)}&stdclass=${item.class}&section=${item.section}&classid=${item.id}`
        }}
        className="navLinks"
        rel="preconnect"
      >
        <Box className="imgSec">
          <img src={getName(item).image} alt="course-img" loading="lazy"/>
        </Box>
        <Box className="textArea">
          <Typography
            className="classSec"
            variant="h5"
            sx={{ color: "recentSub.main" }}
          >
            {item.class}
            {item.section}
          </Typography>
          <Typography
            className="courseName"
            variant="h4"
            sx={{ color: "recentSub.main" }}
            pl={5}
          >
            {getName(item).name}
          </Typography>
        </Box>
        <Box className="overlay">
          <Box className="overlayTxt">
            <Typography className="subject" style={{ fontSize: "20px" }}>
              {getName(item).name}
            </Typography>
            <Typography style={{ fontSize: "40px" }}>
              {" "}
              <b>{item.class}</b>
              <b>{item.section}</b>
            </Typography>
          </Box>
        </Box>
      </NavLink>
    );
  });

  return (
    <Box className="recentSec" sx={{ backgroundColor: "darkblue.color" }}>
      <Typography
        className="recentItemHeader mainHeading"
        sx={{
          color: "recent.titleColor",
          mb: "2",
          backgroundColor: "secondaryColor.main",
        }}
      >
        My Classes
      </Typography>
      <Grid container spacing={1} className="course">
        {isLoading ? (
          <Loader />
        ) : courses.length > 0 ? (
          courses
        ) : (
          <Box className="noCourse" sx={{ color: "red" }}>
            {curLanguage.norecentcourses}
          </Box>
        )}
      </Grid>
    </Box>
  );
};
export default MyClasses;
