import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogContent,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "./AddAttendance.scss";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Label } from "@mui/icons-material";

const AddAttendanceForm = ({ studentInfo, onAttendanceUpdate }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const teacherID = JSON.parse(localStorage.getItem("userInfo"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // Initialize attendanceStatuses array with default values
  const initialAttendanceStatuses = Array(studentInfo.length).fill("P");
  const [attendanceStatuses, setAttendanceStatuses] = useState(
    initialAttendanceStatuses
  );
  const activeFyr = localStorage.getItem("financialYear");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date, "dates")
  };
  console.log(studentInfo, "studentInfo");
  useEffect(() => {
    if (studentInfo && studentInfo.length > 0) {
      setCurrentSlideIndex(studentInfo[0].id);
    }
  }, [studentInfo]);

  const handleSlideChange = (studentId, index) => {
    setCurrentSlideIndex(studentId);
    setCarouselIndex(index);
  };

  const handleAttendanceChange = (event) => {
    const { value } = event.target;
    setAttendanceStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[currentSlideIndex] = value;
      return newStatuses;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDate = selectedDate.$D;
    const formattedMonth = selectedDate.$M + 1;

    const apiUrl = `${process.env.REACT_APP_nodewsPrefix}/addAttendance`;

    try {
      const studentIds = studentInfo.map((stdInfo) => stdInfo.id);

      const studentAttendance = studentIds.map(
        (id) => attendanceStatuses[id] || "P"
      );

      const response = await axios.post(apiUrl, {
        admission_no: studentIds,
        class: studentInfo[0].class,
        section: studentInfo[0].section,
        financial_year: activeFyr,
        added_by: teacherID[0].id,
        day: formattedDate,
        month: formattedMonth,
        attendance: studentAttendance,
      });

      setOpenSnackbar(true);
      setIsDialogOpen(false);
      onAttendanceUpdate(response.data);
      setSelectedDate("")
    } catch (error) {
      console.error("Error adding attendance:", error.message);
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box>
      <Button
        type="submit"
        color="secondaryColor"
        variant="contained"
        onClick={openDialog}
        className="btn"
      >
        Mark Attendance
      </Button>
      <Snackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          sx={{ backgroundColor: "greenColor.color" }}
          message="Attendance added successfully!"
          action={
            <Button color="inherit" size="small" onClick={handleSnackbarClose}>
              OK
            </Button>
          }
        />
      </Snackbar>

      <CustomDialog isOpen={isDialogOpen} width={"sm"}>
        <DialogContent
          sx={{
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
            padding: 0,
          }}
        >
          <Box className="attendanceCard">
            <Box className="carouselContainer">
              <form onSubmit={handleSubmit}>
                <Box
                  py={2}
                  px={3}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"end"}
                >

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      format="DD/MM/YYYY" 
                      renderInput={(params) => <TextField {...params} />}
                    
                    />
                  </LocalizationProvider>
                </Box>

                <Carousel
                  navButtonsAlwaysVisible
                  autoPlay={false}
                  indicators={false}
                  onChange={(index) =>
                    handleSlideChange(studentInfo[index].id, index)
                  }
                >
                  {studentInfo.map((stdInfo, index) => (
                    <Box key={stdInfo.id} className="carouselContent">
                      <Box className="stdDetails">
                        <Box className="stdProfile">
                          <img
                            src={teacherID[0].profileimageurl}
                            alt="profile-img"
                            width={"100%"}
                          />
                        </Box>
                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Name: {stdInfo.fullname}
                          </Typography>
                          <Typography variant="p" sx={{ fontWeight: "bold" }}>
                            ID: {stdInfo.id}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        mt={2}
                        className="attendanceOptionBlock"
                        textAlign={"center"}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="attendance-status"
                            name="attendance-status"
                            value={attendanceStatuses[currentSlideIndex]}
                            defaultValue="P"
                            onChange={handleAttendanceChange}
                            className="attendanceOption"
                          >
                            <FormControlLabel
                              value="P"
                              control={<Radio />}
                              label="P"
                              className="select present"
                            />
                            <FormControlLabel
                              value="H"
                              control={<Radio />}
                              label="H"
                              className="select leave"
                            />
                            <FormControlLabel
                              value="A"
                              control={<Radio />}
                              label="A"
                              className="select absent"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  ))}
                </Carousel>
                <Box className="label-tile " justifyContent={"center"}>
                  <Typography className="P label-data">Present</Typography>
                  <Typography className="H label-data">Half Day</Typography>
                  <Typography className="A label-data">Absent</Typography>
                </Box>
                {carouselIndex === studentInfo.length - 1 ? (
                  <Box my={3} textAlign={"center"}>
                    <Button
                      type="submit"
                      variant="contained"
                      title={`${selectedDate ? "" : "Please select date to enter attendance"}`}
                      className={`${selectedDate ? "btn primaryBtn" : "btn primaryBtn notSelected"}`}
                    >
                      Submit Attendance
                    </Button>
                  </Box>
                ) : (
                  <Box my={3} textAlign={"center"}>
                    <Button
                      onClick={closeDialog}
                      variant="contained"
                      className="smallPrimaryBtn"
                    >
                      close
                    </Button>
                  </Box>
                )}
              </form>
            </Box>
          </Box>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};

export default AddAttendanceForm;
